import React from 'react'
import CopyClipboard from '../../../../commonComponents/CopyClipboard';
import toast from 'react-hot-toast';

const PAYMENT_LINK_HEADING='Payment link generated';
const PAYMENT_LINK_DESCRIPTION=` Your payment link has been successfully created and sent to the user's
registered mobile number. You can copy the link for quick access or
share it directly with the user if needed.`;

const PaymentLinkGenerated = ({PaymentLink}) => {
    return (
      <div className="paymentLinkGeneratedWrapper">
        <div className="paymentLinkHeading">{PAYMENT_LINK_HEADING}</div>
        <div className="paymentDesc">
          {PAYMENT_LINK_DESCRIPTION}
        </div>
        <div
          className="paymentLinkInfo"
          onClick={(event) => {
            handleCopy(event,PaymentLink)
          }}
        >
          Payment Link <CopyClipboard text={PaymentLink} />
        </div>
      </div>
    );
  };
  

export default PaymentLinkGenerated

export   const handleCopy=(event,copyText)=>{
  event.stopPropagation();
  navigator.clipboard
    .writeText(copyText)
    .then(() => toast.success("Copied to clipboard"))
    .catch((error) => toast.error("Failed to copy: " + error));
}