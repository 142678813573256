import React, {useEffect, useState} from "react";
import Button from "../Button";
import {
  validateEmail,
  validateMobile,
  validateName,
} from "../../utils/validate";
import {StyledRegisterSSUserForm} from "./RegisterSSUserForm.styled";
import Backdrop from "../../components/Backdrop";
import SuperSaverPlans from "../../Services/SuperSaverPlans";
import {useNavigate} from "react-router";
import toast from "react-hot-toast";
import Dropdown from "../Dropdown";
import InputField from "../InputField";
import PopupWrapper from "../PopupWrapper";
const RegisterSSUserForm = ({
  popContentStyle,
  heading,
  style,
  showRegistrationForm,
  toggleBackdrop,
}) => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    mobile: "",
    email: "",
    referral_code: "",
    product_type: "",
    category_id: null,
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    mobile: false,
    email: false,
  });
  const [planOptions, setPlanOptions] = useState([]);
  const [isFormReady, setIsFormReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const renderArr = [
    {
      label: "Full name",
      value: formValues.name,
      name: "name",
      type: "text",
    },
    {
      label: "Phone number",
      name: "mobile",
      type: "number",
      value: formValues.mobile,
    },
    {
      label: "Email Id",
      value: formValues.email,
      type: "text",
      name: "email",
    },
    {
      label: "Referral code (Optional)",
      value: formValues.referral_code,
      name: "referral_code",
      type: "text",
    },
  ];

  const handleFormValueChange = (e) => {
    const {name, value} = e.target;
    setFormValues((prevValue) => {
      return {...prevValue, [name]: value};
    });
    if (name === "email") {
      const isValid = !validateEmail(value);
      setFormErrors((prev) => ({...prev, [name]: isValid}));
    }
    if (name === "mobile") {
      const isValid = !validateMobile(value);
      setFormErrors((prev) => ({...prev, [name]: isValid}));
    }
    if (name === "name") {
      const isValid = !validateName(value);
      setFormErrors((prev) => ({...prev, [name]: isValid}));
    }
  };

  useEffect(() => {
    setIsFormReady(
      formValues.name != "" &&
        !formErrors.name &&
        formValues.mobile != "" &&
        formValues.mobile.length > 9 &&
        !formErrors.mobile &&
        formValues.email != "" &&
        !formErrors.email &&
        formValues.category_id
    );
  }, [formValues]);
  const SUPER_SAVER = "Super Saver";
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const allPlans = await SuperSaverPlans.getAllPlans();
        const SSPlans =
          allPlans?.data?.filter((data) => {
            return data?.name === SUPER_SAVER;
          })[0]?.category_details || [];
        if (SSPlans.length > 0) {
          const defaultValue = SSPlans[0];
          const {category_id, name} = defaultValue || {};
          setPlanOptions(
            SSPlans?.map((d) => {
              return {
                value: d?.category_id,
                label: d?.name,
              };
            })
          );
          setFormValues((prev) => ({
            ...prev,
            category_id: category_id,
            ctaValue: {
              label: name,
              value: category_id,
            },
          }));
          setLoading(false);
        } else {
          setLoading(false);
          return false;
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  const handleCreateUser = async () => {
    const payload = {
      ...formValues,
      mobile: "+91-" + formValues.mobile,
      product_type: "SUPER_SAVER",
      ...(formValues.referral_code.length > 0 && {
        referral_code: formValues.referral_code,
      }),
    };
    try {
      setSubmitLoading(true);
      const res = await SuperSaverPlans.createSSuser(payload);
      const {
        data: {user_id},
      } = res;
      navigate("/superSaverUserProfile?user_id=" + user_id);
      toast.success("User created successfully");
      setSubmitLoading(false);
    } catch (error) {
      const {
        data: {message},
      } = error?.response || {};
      setSubmitLoading(false);
      toast.error(message || "Oops something went wrong");
    }
  };

  const handlePlanChange = (ele) => {
    setFormValues((prevValue) => {
      return {
        ...prevValue,
        category_id: ele.value,
        ctaValue: ele,
      };
    });
  };

  if (!showRegistrationForm) return null;
  const PopupWrapperProps = {heading, popContentStyle, toggleBackdrop, style};
  return (
    <PopupWrapper {...PopupWrapperProps}>
      <div className="input">
        {renderArr.map((data, index) => {
          const props = {data, handleFormValueChange, formErrors, index};
          return <InputField {...props} />;
        })}
        <Dropdown
          label={"Plan type"}
          options={planOptions}
          className="dropdown-plans"
          value={formValues?.ctaValue}
          onChange={(ele) => {
            handlePlanChange(ele);
          }}
        />
        <Button
          style={{
            width: " 100%",
            ...(!isFormReady && {
              opacity: 0.5,
            }),
          }}
          onDisable={!isFormReady}
          onButtonClick={() => {
            handleCreateUser();
          }}
          id="createUserCTA"
        >
          {submitLoading ? "Loading.." : "Create User"}
        </Button>
      </div>
    </PopupWrapper>
  );
};

export default RegisterSSUserForm;
