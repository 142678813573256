import { SearchOutlined, CloseOutlined } from '@mui/icons-material';
import { Select, Space, Input, Button, Flex } from 'antd'
import React, { useState, useCallback } from 'react'
import SearchbarStyle from './SearchBar.styles';
import { debounce } from 'lodash';

const SearchBar = ({ options, inputValue, selectOption, onSearchClick }) => {
    const [input, SetInput] = useState(inputValue || "");
    const [selectedDropDownOption, setSelectedDropDownOption] = useState(selectOption || options[0]?.value || "Select Option");
   
    const handleSearchClick = () => {
        onSearchClick(selectedDropDownOption, input);
    };
   
    const handleDropdownChange = (value) => {
        setSelectedDropDownOption(value);
        if (input?.trim() !== "") {
            onSearchClick(value, input);
        }
    };
  
    const handleInputChange = (e) => {
        SetInput(e.target.value);
        debouncedSearch(e.target.value);
    };

    const debouncedSearch = useCallback(debounce((value) => {
        onSearchClick(selectedDropDownOption, value);
    }, 600), [selectedDropDownOption]);

    const findingPlaceHolderValue = (selectedOption) => {
        const existingOption = options?.filter(each => each?.value === selectedOption);
        return existingOption?.length > 0 ? `Search for ${existingOption[0]?.label}` : `Search for Select Option`;
    };

    const handleClearInput = () => {
        SetInput("");
        onSearchClick(selectedDropDownOption, "");
    };

    return (
        <SearchbarStyle>
            <Space.Compact>
                <Select
                    value={selectedDropDownOption}
                    onChange={handleDropdownChange}
                    options={options}
                />
                <Input
                    value={input}
                    placeholder={findingPlaceHolderValue(selectedDropDownOption)}
                    onChange={handleInputChange}
                    onPressEnter={handleSearchClick}
                    suffix={
                        input ? (
                            <CloseOutlined className="clear-icon" onClick={handleClearInput} />
                        ) : (
                            <SearchOutlined className="search-icon" onClick={handleSearchClick} />
                        )
                    }
                />
            </Space.Compact>
        </SearchbarStyle>
    );
};

export default SearchBar;
