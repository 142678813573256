const timeStampDate = (timeStamp) => {
    var date = new Date(timeStamp);
    var yyyy = date.getFullYear();
    var mm = date.getMonth() + 1; // Months start at 0!
    var dd = date.getDate();
    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;
    date = `${yyyy}-${mm}-${dd}`;
    return date;
  };

  const getDate = (startDate, endDate) => {
    if (endDate !== undefined) {
      return `${timeStampDate(startDate)
        .split("-")
        .reverse()
        .join("/")} - ${timeStampDate(endDate).split("-").reverse().join("/")}`;
    } else {
        return `${timeStampDate(startDate).split("-").reverse().join("/")}`;
    }
}

export {
    getDate
}