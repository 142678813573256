import React from "react";
import PaymentBreakdownConfirmation from "../PaymentBreakdownConfirmation";
import Button from "../../../../commonComponents/Button";
import {SuperSaverDashboardService} from "../../../../Services/SuperSaver";
import toast from "react-hot-toast";

const ConfirmationContent = ({
  handleStep,
  formValues,
  user_id,
  setPaymentLink,
}) => {
  const paymentProps = {
    formValues,
    initiate: () => {},
    headingVisible: false,
    handleStep,
  };

  return (
    <>
      <PaymentBreakdownConfirmation {...paymentProps} />
      <Button
        onButtonClick={() => {
          confirmLinkGeneration(
            formValues,
            user_id,
            setPaymentLink,
            handleStep
          );
        }}
        id="confirmLinkGeneration"
      >
        Confirm link generate
      </Button>
    </>
  );
};

export default ConfirmationContent;

export const confirmLinkGeneration = async (
  formValues,
  user_id,
  setPaymentLink,
  handleStep
) => {

try {
  const {
    discount_amount,
    ctaValue,
    dueDate,
    dueAmount,
    is_coin_consume,
    ...restFormValues
  } = formValues;
  const payload =
    dueAmount > 0
      ? {
          ...restFormValues,
          payable_amount: formValues?.payingNow,
          purchase_amount: formValues?.payingNow,

          ...(formValues?.discount_amount > 0 && {
            discount_amount: formValues?.discount_amount,
          }),
          ...(formValues?.dueDate !== null && {
            due_date: new Date(formValues?.dueDate),
          }),
        }
      : {
          ...formValues,
          ...(formValues?.dueDate !== null && {
            due_date: new Date(formValues?.dueDate),
          }),
          purchase_amount: formValues?.amount,
          payable_amount: formValues?.partialPayment || formValues?.amount,
        };
  const res = await SuperSaverDashboardService.getPaymentLink({
    user_id,
    data: payload,
  });
  const {payment_link} = res?.data || {};
  setPaymentLink(payment_link);
  handleStep(3);
} catch (error) {

  toast.error(error?.response?.data?.message||'Oops something went wrong')
  
}

 
};
