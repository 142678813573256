import React, {useEffect, useState} from "react";
import {ClientDetails} from "../../SuperSaverUserProfile.styled";
import Button from "../../../../commonComponents/Button";
import {StyledPaymentLinkGeneration} from "./PaymentLinkGeneration.styled";
import PaymentForm from "../PaymentForm";
import EditBtn from "../../../../commonComponents/EditBtn";
import {handleCopy} from "../PaymentLinkGenerated";
import {amountWithGst} from "../../../../utils/getGstAmount";
import { useSelector } from "react-redux";

const PAYMENT_INITIATED="PAYMENT_INITIATED";
const PAYMENT_PENDING="PAYMENT_PENDING";
const PAID="PAID";
const ELITE="ELITE";
const COPY_LINK_TEXT='Copy payment link';
const GENERATE_LINK_TEXT="Generate payment link";
const UPGRADE_LINK_TEXT="Upgrade payment link"    ;

const PaymentLinkGeneration = ({coinBalance, userDetails}) => {
  const [showLinkGenerationForm, setShowLinkGenerationForm] = useState(false);
  const remoteConfig = useSelector((state) => state?.RemoteConfigReducer);
  const {ProdDashboardPaymentV2FeatureFlagCMS}=remoteConfig;
  const isFeatureEnabled=JSON.parse(ProdDashboardPaymentV2FeatureFlagCMS);
  const toggleBackdrop = () => {
    setShowLinkGenerationForm((state) => !state);
  };
  const [PaymentLink, setPaymentLink] = useState(null);
  const [step, setStep] = useState(1);
  const paymentFormProps = {
    toggleBackdrop,
    showLinkGenerationForm,
    coinBalance,
    userDetails,
    PaymentLink,
    setPaymentLink,
    step,
    setStep,
  };
const {category_type}=userDetails;
  useEffect(() => {
    const {payment_link, status} = userDetails?.last_transaction_details || {};
    const linkStatus = [PAYMENT_INITIATED, PAYMENT_PENDING].some(
      (paymentStatus) => paymentStatus === status
    );
    linkStatus && setPaymentLink(payment_link);
  }, [userDetails.last_transaction_details]);
  const dueAmount = userDetails?.user_payment_details?.due_amount || 0;
  const roundedOffDueAmount = amountWithGst(dueAmount);
  const isDue = roundedOffDueAmount > 0;
  const getButtonText = () => {
    if (PaymentLink) {
      return COPY_LINK_TEXT;
    }
    if (isDue) {
      return GENERATE_LINK_TEXT;
    }

    if (userDetails.subscription_status === PAID) {
      return UPGRADE_LINK_TEXT;
    }

    return GENERATE_LINK_TEXT;
  };

if(!isFeatureEnabled) return null
if(userDetails.subscription_status === PAID&&!isDue&&category_type===ELITE) return null;
  return (
    <StyledPaymentLinkGeneration>
      <div className="clientBox">
        <div className="ClientHed">Payment Link </div>
        <ClientDetails
          className="ClientDetails"
          style={{justifyContent: "space-between"}}
        >
          <div className="data">
            <Button
              buttonClassName="generateLinkCTA"
              onButtonClick={(event) => {
                if (PaymentLink) {
                  handleCopy(event, PaymentLink);
                  return;
                }
                toggleBackdrop();
              }}
            >
              {getButtonText()}
            </Button>
          </div>
          {PaymentLink && (
            <span
              onClick={() => {
                toggleBackdrop();
                setStep(1);
              }}
            >
              <EditBtn />
            </span>
          )}

          <PaymentForm {...paymentFormProps} />
        </ClientDetails>
      </div>
    </StyledPaymentLinkGeneration>
  );
};

export default PaymentLinkGeneration;
