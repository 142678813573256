const getDueAmountInputArr=(formValues)=>{


    return [
        {
          label: "Total Due Amount",
          value: formValues?.dueAmount,
          name: "dueAmount",
          type: "number",
        },
        {
          label: "Amount Paying Now",
          value: formValues?.payingNow,
          name: "payingNow",
          type: "number",
          errMsg: `Amount Paying Now can't be more than Due amount and shouldn't be less than 0`,
        },
        {
          label: "remaining payment amount",
          value: formValues?.dueAmount - formValues?.payingNow,
          name: "remainingAmount",
          type: "number",
    
          inputInactive:
            formValues?.payingNow === null ||
            formValues?.dueAmount - formValues?.payingNow === 0,
        },
        {
          label: "Due Date",
          value: formValues?.dueDate,
          name: "dueDate",
          type: "date",
          inputInactive:
            formValues?.payingNow === null ||
            formValues?.dueAmount - formValues?.payingNow === 0,
        },
      ];
}

const getRenderInputArr=(formValues,coinBalance)=>{

  return [
    {
      label: "Plan Type",
      value: formValues.ctaValue,
      name: "category_id",
      type: "dropdown",
    },
    {
      label: "Plan amount (Without GST) ",
      value: formValues.planAmount,
      name: "planAmount",
      type: "number",
    },
    {
      label:
        formValues.is_coin_consume && formValues.consumed_coins
          ? `Coins consumable:${formValues.consumed_coins} (Total:${coinBalance})`
          : `use available coins (Total:${coinBalance})`,
      value: formValues.is_coin_consume,
      name: "is_coin_consume",
      type: "checkBox",
      inputInactive: coinBalance == 0,
    },

    {
      label: "Payable amount (with GST)",
      value: formValues.amount,
      type: "number",
      name: "amount",
      errMsg: `Amount should be greater than 0`,
    },
    {
      label: "Discount",
      name: "discount_amount",
      type: "number",
      disable: true,
      value: formValues?.discount_amount,
      inputInactive: formValues?.discount_amount <=0,
    },
    {
      label: "Do you want to do partial payment",
      value: formValues.isPartialPayment,
      name: "isPartialPayment",
      type: "checkBox",
    },
    {
      label: "partial payment amount",
      value: formValues.partialPayment,
      name: "partialPayment",
      type: "number",
      inputInactive: !formValues.isPartialPayment,
      errMsg: `partial payment amount can't be more than Payable amount`,
    },
    {
      label: "Due date to pay rest",
      value: formValues.dueDate,
      name: "dueDate",
      type: "date",
      inputInactive: !formValues.isPartialPayment,
    },
  ];
}

export {getDueAmountInputArr,getRenderInputArr}
