import axios from 'axios';
import { auth } from '../firebase';
import { LOCAL_DB_KEYS } from '../constants/localStorage';
import toast from 'react-hot-toast';

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const BASE_URL_STAGE = process.env.REACT_APP_STAGING_API_ENDPOINT;
const BASE_URL_PROD = process.env.REACT_APP_PRODUCTION_API_ENDPOINT;
const BASE_URL_LOCAL = process.env.REACT_APP_LOCAL_API_ENDPOINT;

// const AUTH_TOKEN_LS = 'token';
// export const USED_ID_LS = 'userId';
// export const AUTH_TOKEN_CREATION = 'tokenCreationTimestamp';
const TOKEN_EXPIRY_DURATION = 56 * 60 * 1000;

let BASE_URL = BASE_URL_STAGE;

if (ENVIRONMENT === 'PRODUCTION') {
	BASE_URL = BASE_URL_PROD;
} else if (ENVIRONMENT === 'LOCAL') {
	BASE_URL = BASE_URL_LOCAL;
} else {
	BASE_URL = BASE_URL_STAGE;
}

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded';

const isTokenAboutToExpire = () => {
	const authTokenCreationTime = localStorage.getItem(
		LOCAL_DB_KEYS.AUTH_TOKEN_CREATION
	);
	return new Date().getTime() - +authTokenCreationTime > TOKEN_EXPIRY_DURATION;
};

const getAuthUser = () => {
	return new Promise((resolve, reject) => {
		auth.onAuthStateChanged((user) => {
			if (user) {
				resolve(user);
			} else {
				const auth = localStorage.getItem(LOCAL_DB_KEYS.AUTH_TOKEN_LS);
				localStorage.clear();
				if (auth){
					toast.error('Session expired')
				}				
				reject('auth user access failed');
			}
		});
	});
};

export const getAuthToken = async () => {
	const auth = localStorage.getItem(LOCAL_DB_KEYS.AUTH_TOKEN_LS);
	if (!isTokenAboutToExpire()) {
		return auth;
	} else {
		const user = await getAuthUser();
		const token = await user.getIdToken(true);
		localStorage.setItem(LOCAL_DB_KEYS.AUTH_TOKEN_LS, token);
		localStorage.setItem(
			LOCAL_DB_KEYS.AUTH_TOKEN_CREATION,
			new Date().getTime()
		);
		return token;
	}
};

export const getHeaders = async (_headers) => {
	const token = await getAuthToken();
	return {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Authorization: token,
		token,
		Source: 'cms',
		..._headers
	};
};

const nonAuthHeaders = {
	'Content-Type': 'application/json',
	Accept: 'application/json',
	Source: 'web'
};

export const httpClient = {
	get: async (path, params = {}, _headers = {}, isAuth = true) => {
		let headers;
		if (isAuth) {
			headers = await getHeaders(_headers);
		} else {
			headers = nonAuthHeaders;
		}
		let options = { params, headers };
		const res = await axios.get(path, options);
		return res;
	},
	post: async (path, data, params = {}, _headers = {}, isAuth = true) => {
		let headers;
		if (isAuth) {
			headers = await getHeaders(_headers);
		} else {
			headers = nonAuthHeaders;
		}
		const options = { params, headers };
		const res = await axios.post(path, data, options);
		return res;
	},
	patch: async (path, data, params = {}, _headers = {}, isAuth = true) => {
		let headers;
		if (isAuth) {
			headers = await getHeaders(_headers);
		} else {
			headers = nonAuthHeaders;
		}
		const options = { params, headers };
		const res = await axios.patch(path, data, options);
		return res;
	},
	put: async (path, data, params = {}, _headers = {}, isAuth = true) => {
		let headers;
		if (isAuth) {
			headers = await getHeaders(_headers);
		} else {
			headers = nonAuthHeaders;
		}
		const options = { params, headers };
		const res = await axios.put(path, data, options);
		return res;
	},
	delete: async (path, data, params = {}, _headers = {}, isAuth = true) => {
		let headers;
		if (isAuth) {
			headers = await getHeaders(_headers);
		} else {
			headers = nonAuthHeaders;
		}
		const options = { data, params, headers };
		const res = await axios.delete(path, options);
		return res;
	}
};
