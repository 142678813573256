import React, { useCallback } from "react";
import debounce from "lodash/debounce";
import { useSSPlan } from "../../CustomHooks/useSSPlan";
import CheckBoxComponent from "../../../ConsultationExperience/components/CheckBoxComponent";
import Button from "../../../../commonComponents/Button";
import { confirmLinkGeneration } from "../ConfirmationContent";
import { Spin } from "antd";
import Dropdown from "../../../../commonComponents/Dropdown";
import InputField from "../../../../commonComponents/InputField";

const AMOUNT = "amount";
const DISCOUNTED_AMOUNT = "discount_amount";
const IS_COIN_CONSUME = "is_coin_consume";

const InitialPaymentForm = ({
  isDue,
  dueAmountInputArr,
  renderInputArr,
  setFormValues,
  formValues,
  getPaymentCalc,
  isFormReady,
  formErrors,
  handleStep,
  user_id,
  setPaymentLink,
  loading,
  currentPlan,
  isFreemium,
  userDetails,
}) => {
  const dependencyArr = [{ id: 1 }];

  const handleInputChange = useCallback(
    //using deboucing for 800 milisec so that we can call api after user have entered whole amount
    debounce((formValues) => {
      getPaymentCalc(formValues);
    }, 800),
    []
  );

  const handleFormValueChange = (e) => {
    const { name, value } = e?.target || e;

    setFormValues((prevValue) => {
      let newFormValues = prevValue;
      newFormValues = { ...prevValue, [name]: value };
      if (name === AMOUNT || name === DISCOUNTED_AMOUNT) {
        newFormValues = {
          ...newFormValues,
          [name === AMOUNT ? DISCOUNTED_AMOUNT : AMOUNT]: null,
        };

        handleInputChange(newFormValues);
      }
      if (name === IS_COIN_CONSUME) {
        if (value) {
          newFormValues = {
            ...newFormValues,
            discount_amount: 0,
          };
          getPaymentCalc(newFormValues);
        } else {
          newFormValues = { ...newFormValues, amount: "", discount_amount: "" };
        }
      }
      return newFormValues;
    });
  };
  const handleDefaultValue = (defaultValue) => {
    const {
      category_id,
      name,
      discounted_price,
      label = null,
      value = null,
      current_plan_discounted_price = null,
    } = isDue || isFreemium ? currentPlan : defaultValue || currentPlan || {};

    const ctaValue = label ? { label, value } : null;
    setFormValues((prev) => ({
      ...prev,
      category_id: value || category_id,
      planAmount:!userDetails?.is_payment_pending ? defaultValue?.discounted_price-currentPlan?.current_plan_discounted_price:currentPlan?.current_plan_discounted_price,
      ctaValue: ctaValue || {
        label: name,
        value: category_id,
      },
      planType: label || name,
    }
    ));
  };

  const [planOptions, planLoading] = useSSPlan(
    handleDefaultValue,
    currentPlan,
    isFreemium
  );
  const handlePlanChange = (ele) => {
    
    const { discounted_price } = ele;
    setFormValues((prevValue) => {
      const formVal = {
        ...prevValue,
        category_id: ele.value,
        ctaValue: ele,
        planAmount: !userDetails?.is_payment_pending?discounted_price - currentPlan?.current_plan_discounted_price:discounted_price,
        discount_amount: 0,
        is_coin_consume: false,
        planType: ele?.label,
      };
      return formVal;
    });
  };
  const handleGenerate = () => {
    if (isDue) {
      confirmLinkGeneration(formValues, user_id, setPaymentLink, handleStep);
      return;
    }
    handleStep();
  };
  return (
    <Spin spinning={planLoading || loading}>
      <div className="input">
        {(isDue ? dueAmountInputArr : renderInputArr).map((data, index) => {
          const props = { data, handleFormValueChange, formErrors, index };
          if (data.inputInactive) return null;
          if (data.type === "checkBox") {
            return (
              <CheckBoxComponent
                id={index}
                radioArrData={dependencyArr.map((d) => ({
                  ...d,
                  text: data.label,
                  isSelected: data.value,
                }))}
                style={{
                  width: "100%",
                  margin: "2px 20px",
                }}
                handleRadioChange={() => {
                  handleFormValueChange({ value: !data.value, name: data.name });
                }}
              />
            );
          }
          if (data.type === "dropdown") {
            return (
              <Dropdown
                id={index}
                label={"Plan type"}
                options={planOptions}
                className="dropdown-plans"
                value={formValues?.ctaValue}
                onChange={(ele) => {
                  handlePlanChange(ele);
                }}
              />
            );
          }
          return (
            <InputField {...props} formErrors={formErrors} index={index} disabled={data?.disable} />
          );
        })}

        <Button
          style={{
            width: " 100%",
            ...(!isFormReady && {
              opacity: 0.5,
              cursor: !isFormReady? "not-allowed" : "pointer"
            }),
          }}
          onDisable={!isFormReady}
          onButtonClick={() => {
            handleGenerate();
          }}
          id="createUserCTA"
        >
          Generate link
        </Button>
      </div>
    </Spin>
  );
};

export default InitialPaymentForm;
