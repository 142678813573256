import React from 'react'
import Backdrop from '../../components/Backdrop';
import { StyledRegisterSSUserForm } from '../RegisterSSUserForm/RegisterSSUserForm.styled';

const PopupWrapper = ({
    children,
    heading,
    popContentStyle,
    toggleBackdrop,
    style,
  }) => {
    return (
      <Backdrop toggleBackdrop={toggleBackdrop}>
        <StyledRegisterSSUserForm style={style}>
          <span
            className="closePop"
            role="button"
            onClick={() => {
              toggleBackdrop();
            }}
          >
            &times;
          </span>
          {heading && (
            <div className="popContent" style={popContentStyle}>
              <b>{heading} </b>
            </div>
          )}
          {children}
        </StyledRegisterSSUserForm>
      </Backdrop>
    );
  };

export default PopupWrapper