import styled from 'styled-components';
import { dim, theme } from '../../styles/theme';

export const Wrapper = styled.div`
	background-color: ${theme.color.white_0};
	padding: ${dim._8px};
	box-sizing: border-box;
	border-radius: ${dim._8px};
	position: relative;
height:100%;
	.heading {
		padding: 0px ${dim._8px};
		display: flex;
		align-items: center;
		gap: ${dim._8px} ;
	}

	.desc {
		font-size: ${dim._12px};
	}

	.saving-card {
		margin-top: ${dim._16px};
		padding: ${dim._16px};
		box-sizing: border-box;
		background-color: ${theme.color.grey_60};
		border-radius: 4px;
		font-size: ${dim._10px};
		font-weight: bold;
	}

	.hr {
		width: 100%;
		box-sizing: border-box;
		margin: ${dim._4px} 0px;
		background-color: ${theme.color.grey_20};
	}

	.flex-row {
		padding: 0px ${dim._8px};
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: ${dim._12px};
	}

	.text-b {
		font-weight: 600;
		padding: ${dim._8px} ${dim._8px};
		margin:0;
	}

	.sub-list {
		margin-top: ${dim._12px};
		padding-left: ${dim._8px};
		padding-right: ${dim._8px};
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: ${dim._8px};
		color: ${theme.color.black_15};
	}

	.btn-container {
		margin: ${dim._16px} 0px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.error {
		margin-bottom: ${dim._16px};
		color: ${theme.color.red_25};
	}

	.bottom-sheet {
		margin: 8px 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
	}

	.images-safe {
		height: 50px;
	}

	.message-closed {
		margin-top: 12px;
		padding: 16px;
		box-sizing: border-box;
		background-color: #ffe8e8;
	}

	.closed-text {
		width: 100%;
		color: #f40000;
		font-size: 13px;
		font-weight: 500;
	}

	.message-open-with-cond {
		padding: 12px;
		box-sizing: border-box;
		background-color: #ffe8e8;
		margin-bottom: 16px;
	}

	.open-with-cond {
		display: flex;
		column-gap: 12px;
		align-items: center;
	}
	.discount-box {
		background-color: ${theme.color.blue_80};
		color: #fff;
		font-weight: 700;
	}
`;
