import {RemoteConfigActions} from './GetRemoteConfigActions';

const initialState = {
	ProdDashboardPaymentV2FeatureFlagCMS: false,
	RentAgreementFeatureFlag: false
};

export const RemoteConfigReducer = (
	state = initialState,
	payload = {}
) => {
	switch (payload?.type) {
		case RemoteConfigActions.SAVE_REMOTE_CONFIG_VALUES:
			return {
				...state,
				ProdDashboardPaymentV2FeatureFlagCMS:payload.data.ProdDashboardPaymentV2FeatureFlagCMS,
				RentAgreementFeatureFlag:payload.data.RentAgreementFeatureFlag

			};
		default:
			return state;
	}
};
