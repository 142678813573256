import {
	getRemoteConfig,
	fetchAndActivate,
	getValue
} from 'firebase/remote-config';
import toast from 'react-hot-toast';
import { app } from '../firebase';
// Initialize Remote Config
const remoteConfig = getRemoteConfig(app);

// Optional: Set default parameter values
remoteConfig.defaultConfig = {
	Prod_Dashboard_Payment_v2_Feature_flag_CMS: false,
	rent_agreement_feature_flag: false
};

// Set minimum fetch interval in seconds
remoteConfig.settings = {
	minimumFetchIntervalMillis: 36000 // 1 hour
};

// Fetch and activate Remote Config values
export const fetchRemoteConfigValues = async () => {
	try {
		const res = await fetchAndActivate(remoteConfig);
	} catch (error) {
		toast.error('Failed to fetch remote config keys');
	}
};

// Get a specific Remote Config value
export const getRemoteConfigValue = (key) => {
	return getValue(remoteConfig, key).asString();
};
