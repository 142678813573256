import React, {useState, useEffect, useLayoutEffect} from "react";
import Layout from "./container/Layout";
import "./App.css";
import posthog from "posthog-js";
import AdvisorData from "./Context/Advisor/AdvisorData";
import Routing from "./Routes/Index";
import toast, {Toaster} from "react-hot-toast";
import {getMetaData} from "./Services/metaData";
import {useDispatch} from "react-redux";
import {Actions} from "./Routes/store/action";
import {useSalaryComponent} from "./container/PayslipManualReview/Hooks/useSalaryComponent";
import {USER_DATA, USER_TYPE} from "./utils/localStorageKeys";
import {SentryActivate} from "./Integrations/Sentry";
import { useLocation } from "react-router";
import { useRemoteConfig } from "./remoteConfig/useRemoteConfig";

const App = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [type, setType] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [user, setUser] = useState({});
  const dispatch = useDispatch();
  const handleLogoutButton = () => {
    setLoggedIn(false);
    setType("");
    localStorage.clear();
  };
  const {pathname} = useLocation();
  const handleGetMetaData = async () => {
    try {
      const metaData = await getMetaData();
      const data = await metaData?.data;
      dispatch({type: Actions.SET_META_DATA, data});
    } catch (error) {
      toast.error(error ? error : error.message);
    }
  };

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("loggedIn");

    if (isLoggedIn) {
      const foundUser = JSON.parse(isLoggedIn);
      setLoggedIn(foundUser);
    } else {
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (loggedIn) {
      const userData = JSON.parse(localStorage.getItem(USER_DATA));
      const type = JSON.parse(localStorage.getItem(USER_TYPE));
      setUser(userData);

      setType({...type});
    }
  }, [loggedIn]);

  const handleLogin = () => {
    setLoggedIn(true);
  };

  useLayoutEffect(() => {
    SentryActivate();
    handleGetMetaData();
  }, [loggedIn]);
  
  useRemoteConfig();
  useSalaryComponent();

  const POSTHOG_DSN = process.env.REACT_APP_POSTHOG_DSN;
  const ENV_PRODUCTION = process.env.REACT_APP_ENVIRONMENT;

  ENV_PRODUCTION === "PRODUCTION" &&
    posthog.init(POSTHOG_DSN, {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
    });

  useEffect(() => {
    posthog.identify(user?.id, {
      email: userEmail,
    });
  }, [user?.id, userEmail]);



  useEffect(() => {

    posthog.capture("$pageview", {
      $current_url: pathname,
    });
  }, [pathname]);

  return (
    <AdvisorData>
      <Layout
        loggedIn={loggedIn}
        handleLogoutButton={handleLogoutButton}
        userEmail={userEmail}
        user={user}
        userType={type?.user_roll}
      >
        <Routing
          loggedIn={loggedIn}
          setUser={setUserEmail}
          setLoggedIn={handleLogin}
          handleLogoutButton={handleLogoutButton}
          setType={setType}
          userType={type?.user_roll}
        />
        <Toaster
          toastOptions={{
            style: {
              fontSize: "0.875rem",
              alignItems: "center",
              backgroundColor: "#202124",
              border: "none",
              borderRadius: "4px",
              color: "#fff",
              display: "flex",
              flexWrap: "wrap",
              fontWeight: "400",
              padding: "8px 24px",
              boxSizing: "border-box",
            },
          }}
          position="bottom-left"
        />
      </Layout>
    </AdvisorData>
  );
};

export default App;
