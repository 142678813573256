import styled from "styled-components";
import {dim} from "../../../../styles/theme";

export const StyledPaymentLinkGeneration = styled.div`
  .generateLinkCTA {
    width: auto;
    margin: auto;
  }
  #confirmLinkGeneration {
    height: auto;
    margin: ${dim._24px} ${dim._24px} 0;
    width: calc(100% - ${dim._48px});
  }

  .clipboard {
    display: flex;
    align-items: center;
    padding: 0px ${dim._10px} ${dim._2px} ${dim._10px};
    position: relative;
  }

  .paymentLinkHeading {
    font-weight: 700;
    font-size: ${dim._24px};
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .paymentLinkInfo {
    display: flex;
    justify-content: center;
    text-decoration: underline;
    font-size: ${dim._20px};
  }
  .paymentWrapper {
    padding-bottom: ${dim._16px};
  }
  .paymentLinkGeneratedWrapper {
    width: auto;
    display: flex;
    flex-direction: column;
    gap: ${dim._12px};
    padding: ${dim._24px};
  }
  .input {
    padding-top: ${dim._26px};
  }
`;
