import { getMsDifference } from "./converttimestamptoday";

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const TimeUtil = {
  getTimeStamp: (dateTime) => {
    return new Date(dateTime).getTime() / 1000;
  },

  getCurrentTimeStamp: () => {
    const now = Date.now(); // Unix timestamp in milliseconds
    return now;
  },

  yearToTimestamp: (year) => {
    // Create a Date object for January 1 of the specified year
    const date = new Date(year, 0, 1);

    // Get the timestamp in milliseconds
    const timestamp = date.getTime();

    return timestamp;
  },

  getDifferenceInMinutes: (nextTimeStamp) => {
    let nextAvailDate = new Date(nextTimeStamp).getDate();
    let nextAvailMonth = new Date(nextTimeStamp).getMonth();
    let nextAvailYear = new Date(nextTimeStamp).getFullYear();
    let nextAvail = new Date(nextTimeStamp).getTime();
    let currAvail = new Date().getTime();
    let diff = (nextAvail - currAvail) / 1000;
    let minutes = diff / 60;
    let finalMin = Math.floor(Math.floor(minutes));
    let num = finalMin;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let min = (hours - rhours) * 60;
    let rminutes = Math.round(min);
    if (rhours > 12 && rhours < 38) {
      return "Tomorrow";
    } else if (rhours < 1) {
      return "in" + " " + rminutes + " " + "min";
    } else if (rminutes === 0) {
      return "Now";
    } else if (rhours > 38) {
      return (
        "on" +
        " " +
        nextAvailDate +
        "/" +
        (nextAvailMonth + 1) +
        "/" +
        nextAvailYear
      );
    } else {
      return "after" + " " + rhours + " " + "hr";
    }
  },

  getDifferenceInMinutes2: (nextTimeStamp) => {
    let nextAvailDay = new Date(nextTimeStamp).getDate();
    let nextAvailMonth = new Date(nextTimeStamp).getMonth();
    let nextAvailYear = new Date(nextTimeStamp).getFullYear();
    const nextAvailDate =
      nextAvailYear + "-" + (nextAvailMonth + 1) + "-" + nextAvailDay;
    return nextAvailDate;
  },

  getDateTime: (timeStamp) => {
    let date = new Date(timeStamp);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hour = date.getHours();
    let min = date.getMinutes();

    month = (month < 10 ? "0" : "") + month;
    day = (day < 10 ? "0" : "") + day;
    hour = (hour < 10 ? "0" : "") + hour;
    min = (min < 10 ? "0" : "") + min;

    let str =
      date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min;
    return str;
  },

  getDateWiseTimeSlots: (slots = []) => {
    let slotsWithDateTime = slots.map((item) => {
      return {
        ...item,
        start_timestamp: item.start_time,
        end_timestamp: item.end_time,
        start_date_time: TimeUtil.getDateTime(item.start_time),
        end_date_time: TimeUtil.getDateTime(item.end_time),
        label:
          TimeUtil.getDateTime(item.start_time).split(" ")[1] +
          "-" +
          TimeUtil.getDateTime(item.end_time).split(" ")[1],
        value: item.id,
        isDisabled: item.flag === "BOOKED",
      };
    });
    return slotsWithDateTime;
  },

  nearestAvailableSlot: (slots, today) => {
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].flag === "AVAILABLE") {
        return TimeUtil.getDateTime(slots[i].start_time).split(" ")[0];
      }
    }
    return today;
  },

  nearestAvailableSlotId: (slots, today) => {
    for (let i = 0; i < slots.length; i++) {
      if (slots[i].flag === "AVAILABLE") {
        return slots[i];
      }
    }
    return today;
  },
};

export default TimeUtil;

export const getFormattedDate = (timeStamp) => {
  var date = new Date(timeStamp);

  var month = date.getMonth() + 1;
  var day = date.getDate();

  month = (month < 10 ? "0" : "") + month;
  day = (day < 10 ? "0" : "") + day;

  var str = date.getFullYear() + "-" + month + "-" + day; //+

  return str;
};

export function getDayName(timestamp) {
  const date = new Date(+timestamp);
  const dayIndex = date.getDay();
  return daysOfWeek[dayIndex];
}

export const getFormattedDateAllWithMonthName = (timeStamp) => {
  let date = new Date(timeStamp);
  let month = date.getMonth();
  let day = date.getDate();
  let year = date.getFullYear();
  return months[month] + " " + day + " " + year;
};

export const getDateAndTime = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const _date = `${day < 10 ? `0${day}` : day}-${months[month]}-${year}`;
  return _date;
};

export const changeDateFormate = (value) => {
  const timestamp = +value;
  return `${new Date(timestamp).getDate()} 
          ${months[new Date(timestamp).getMonth()]} ’
         ${new Date(timestamp).getFullYear()}`;
};

export function getDateDifference(
  previousTimeStamp,
  currentTimeStamp = new Date()
) {
  const previousDate = new Date(previousTimeStamp);
  const currentDate = new Date(currentTimeStamp);

  let timeDifference = currentDate - previousDate; // Time difference in milliseconds

  // Ensure the time difference is never negative
  if (timeDifference < 0) {
    timeDifference = 0;
  }

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

  return {
    previousDate,
    currentDate,
    days,
    hours,
    minutes,
    seconds,
  };
}

export const dateFormatter = (timestamp) => {
  return `${getDayName(timestamp)},
				${changeDateFormate(timestamp)}`;
};

export const timeUtil = {
  getTime: (date = new Date()) => {
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${year}-${month < 10 ? `0${month}` : month}-${
      day < 10 ? `0${day}` : day
    }`;
  },

  getDateAndTime: (date = new Date()) => {
    const _date = new Date(date);
    const year = _date.getFullYear();
    const month = _date.getMonth();
    const day = _date.getDate().toString().padStart(2, "0");
    const time = timeUtil.getTimeHM_AMPM(_date);
    const __date = `${day} ${months[month]} ${year}`;
    return { time, date: __date };
  },

  getTimeHM_AMPM: (_date = new Date()) => {
    const date = new Date(_date);
    let hr = date.getHours();
    let mt = date.getMinutes();
    let ampm = "AM";

    if (hr < 12) {
      ampm = "AM";
    } else if (hr === 12) {
      ampm = "PM";
      hr = 12;
    } else {
      ampm = "PM";
      hr = hr - 12;
    }

    return `${hr < 10 ? "0" + hr : hr}:${mt < 10 ? "0" + mt : mt} ${ampm}`;
  },

  getTime24HourFormat: (_date = new Date()) => {
    const date = new Date(_date);
    let hr = date.getHours();
    let mt = date.getMinutes();
    return `${hr < 10 ? "0" + hr : hr}:${mt < 10 ? "0" + mt : mt}`;
  },

  getFormattedDate: (date = new Date()) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const _date = `${months[month]} ${
      day < 10 ? `0${day}` : day
    }, ${year}`;
    return _date;
  },

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    let ccc = [year, month, day].join("-");
    return ccc;
  },
  
  getFormattedDateMonthYear: (timeStamp) => {
    let date = new Date(timeStamp);
    let month = date.getMonth();
    let day = date.getDate();
    let year = date.getFullYear();
    return { month: months[month], day, year };
  },
};

export const getFormattedDateMonthYear = (timeStamp) => {
  let date = new Date(timeStamp);
  let month = date.getMonth();
  let day = date.getDate();
  let year = date.getFullYear();
  return {
    month: months[month],
    day,
    year,
    time: timeUtil.getDateAndTime(timeStamp).time,
  };
};

export const getSlashDateFormat = (timestamp) => {
  const date = new Date(timestamp);
  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return `${day}/${month}/${year}`;
};
const getTotalHoursDiff = (date) => {
  return Math.floor(getMsDifference(date) / 1000 / 60 / 60);
};
export function isTimestampMoreThanRequiredHoursOld(date,requiredHours=2) {
  let hours = getTotalHoursDiff(date);

  return Math.abs(hours) <= requiredHours ? false : true;
}

export function combineDateAndTime(dateTimestamp, timeTimestamp) {

  const dateObj = new Date(dateTimestamp);
  const timeObj = new Date(timeTimestamp);
  const year = dateObj.getFullYear();
  const month = dateObj.getMonth();
  const day = dateObj.getDate();
  const hours = timeObj.getHours();
  const minutes = timeObj.getMinutes();
  const seconds = timeObj.getSeconds();
  const combinedDateTime = new Date(year, month, day, hours, minutes, seconds);

  return combinedDateTime.getTime();
};