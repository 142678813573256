import React, { useEffect, useState } from 'react'
import { UploadRentAgreementDocStyled } from './UploadRentAgreementForm.styled'
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import UploadFile from '../uploadFile';
import { toast } from "react-hot-toast";
import { UpdateStatusRentAgreement } from '../../Services/RentAgriment';
import { RentAgreementUpload } from '../../utils/rentAgreementUrl';
import { LOCAL_DB_KEYS } from '../../constants/localStorage';

const UploadRentAgreementDoc = ({handleCross, userId, username, setStatus, setShow}) => {
    const RENT_AGREEMENT = 'RENT_AGREEMENT'
    const COMPLETED = 'COMPLETED'
    const [buttonActive, setButtonActive] = useState(true);
    const [uploadImage, setUploadImage] = useState(false);
    const [originalLink, setOriginalLink] = useState("");
    const [fileIdentifier, setFileIdentifier] = useState('')

    const handleCancelUpload = () => {
        setUploadImage(null);
        setFileIdentifier('');
    };

    const handleImageUpload = (e) => {
        const imageFile = e.target.files[0];
        const name = e.target.files[0].name;
        const lastDot = name.lastIndexOf(".");
        const fileName = name.substring(0, lastDot);
        const ext = name.substring(lastDot + 1).toUpperCase();
        
        const ReturnValueSetter = (identifier) => {
            setOriginalLink(URL.createObjectURL(imageFile));
            setUploadImage(true);
            setFileIdentifier(identifier);
        };
        
        const currentUser = localStorage.getItem(LOCAL_DB_KEYS.USER_DETAILS)
        const displayname = JSON.parse(currentUser).displayName
        const financialYearId = localStorage.getItem(LOCAL_DB_KEYS.FINANCIAL_YEAR_ID)
        const preSignedUrl = RentAgreementUpload(
            RENT_AGREEMENT,
            username ,
            displayname,
            financialYearId,
            fileName,
            ext,
            ReturnValueSetter,
            imageFile
        );
        toast.promise(preSignedUrl, {
            loading: "Document Uploading...",
            success: "Document Uploaded",
            error: "Oops something went wrong",
        });
    };

    const handleSubmit = () => {
        setStatus(COMPLETED)
        UpdateStatusRentAgreement(
                userId,
                COMPLETED,
                {
                    document_identifier:fileIdentifier
                }
              );
        setShow(false)
    }
    useEffect(() => {
            setButtonActive(fileIdentifier.length > 0);
        }, [fileIdentifier]);
  return (
    <UploadRentAgreementDocStyled disable={buttonActive}>
        {" "}
        <span className="closePop" onClick={handleCross}>
            &times;
        </span>
        <div className='popContent' >
            <div className="" style={{ display: "flex" }}>
                 <b className="popTitle"> Upload Rent-agreement Document </b>
            </div>
            <div className="inputdiv">
                <p className="titleLabel">Document Upload :</p>
                <UploadFile
                    fileId="thumbnail"
                    supersaver={true}
                    labelName="Upload Documemnt"
                    icon={
                        <FileUploadOutlinedIcon
                            style={{
                                fontSize: 50,
                                color: "#85BB65",
                            }}
                        />
                    }
                    uploadClassName="uploadRentAgreementDoc"
                    uploaded={uploadImage}
                    uploadLink={originalLink}
                    onFileUpload={handleImageUpload}
                    cancelUpload={handleCancelUpload}
                    acceptFileType = 'application/pdf'
                />
             </div>
             <div className="submitButton">
                <label className="submitLabel" onClick={handleSubmit}>
                    Submit
                </label>
             </div>
        </div>{" "}
    </UploadRentAgreementDocStyled>
  )
}

export default UploadRentAgreementDoc