import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { amountWithGst } from "../../../../utils/getGstAmount";
import { dim } from "../../../../styles/theme";
import { SuperSaverDashboardService } from "../../../../Services/SuperSaver";
import ConfirmationContent from "../ConfirmationContent";
import InitialPaymentForm from "../InitialPaymentForm";
import PaymentLinkGenerated from "../PaymentLinkGenerated";
import toast from "react-hot-toast";
import { getDueAmountInputArr, getRenderInputArr } from "./constant";
import { convertToPascalCase } from "../../../../utils/textUtil";
import PopupWrapper from "../../../../commonComponents/PopupWrapper";

const InitialFormValues = {
  product_type: "",
  planType: "",
  category_id: null,
  planAmount: null,
  dueAmount: null,
  discount_amount: null,
  is_coin_consume: false,
  partialPayment: null,
  isPartialPayment: false,
  payingNow: null,
};

const FREEMIUM = "FREEMIUM";
const USER_ID = "user_id";

const PaymentForm = ({
  toggleBackdrop,
  showLinkGenerationForm,
  coinBalance,
  userDetails,
  PaymentLink,
  setPaymentLink,
  step,
  setStep,
}) => {
  const [searchParams] = useSearchParams();
  const user_id = searchParams.get(USER_ID);
  const {
    user_payment_details: { due_amount, version = 0 },
    subscription_status,
    category_id,
    category_type,
    current_plan_discounted_price,
    is_payment_pending,
  } = userDetails;
  const dueAmount = (version === 0 ? due_amount : due_amount / 1.18) || 0;
  const isFreemium = subscription_status === FREEMIUM || 0;
  const currentPlan = {
    value: category_id,
    label: convertToPascalCase(category_type) + " Plan",
    current_plan_discounted_price,
  };
  const roundedOffDueAmount = amountWithGst(dueAmount);
  const isDue = roundedOffDueAmount > 0;
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState(InitialFormValues);
  const [isFormReady, setIsFormReady] = useState(false);
  const [formErrors, setFormErrors] = useState({
    partialPayment: false,
    amount: false,
  });
  const renderInputArr = getRenderInputArr(formValues, coinBalance, currentPlan, is_payment_pending);
  const dueAmountInputArr = getDueAmountInputArr(formValues);

  const handleIsFormReadyState = () => {
    if (formValues?.planType === "" || !formValues?.planType) {
      setIsFormReady(false)
      return
    }
  
    if (isDue) {
      if (
        !formValues?.payingNow ||
        formValues?.payingNow === "" ||
        +formValues?.payingNow <= 0
      ) {
        setIsFormReady(false);
        return
      }

      if (+formValues?.payingNow > formValues?.dueAmount) {
        setIsFormReady(false);
        return
      }

      if (+formValues?.payingNow < formValues?.dueAmount && !formValues?.dueDate) {
        setIsFormReady(false);
        return
      }

      setIsFormReady(true);
      return
    }
    if (formValues?.isPartialPayment) {
      if (

        !formValues?.amount ||
        formValues?.amount === "" ||
        +formValues?.amount <= 0
      ) {
        setIsFormReady(false);
        return
      }

      if (!formValues?.dueDate) {
        setIsFormReady(false);
        return
      }

      if (
        +formValues?.partialPayment <= 0 ||
        +formValues?.partialPayment > +formValues?.amount
      ) {
        setIsFormReady(false);
        return
      }
      setIsFormReady(true);
      return
    }
    if (!formValues?.isPartialPayment) {
      if (
        !formValues?.amount ||
        formValues?.amount === "" ||
        +formValues?.amount <= 0
      ) {
        setIsFormReady(()=>false);
        return
      }
      setIsFormReady(true);
      return
    }

  }

  useEffect(() => {
    setIsFormReady(() => {
      if (isDue) {
        if (formValues?.dueAmount - formValues?.payingNow === 0) {
          return formValues?.payingNow?.length > 0;
        }
        return (
          formValues?.payingNow?.length > 0 &&
          formValues?.dueDate?.length > 0 &&
          formValues.payingNow <= formValues.dueAmount
        );
      }
      if (formValues.isPartialPayment) {
        return (
          formValues.category_id &&
          formValues?.partialPayment?.length > 0 &&
          +formValues?.partialPayment <= +formValues?.amount &&
          formValues?.dueDate?.length > 0
        );
      }

      return formValues.category_id && formValues.amount;
    });
  }, [formValues, isDue]);

  useEffect(() => {
    setFormErrors((error) => {
      return {
        ...error,
        partialPayment: +formValues?.partialPayment > +formValues?.amount || (formValues?.partialPayment && +formValues?.partialPayment <= 0),
        amount: formValues?.amount !== "" && +formValues?.amount <= 0,
        payingNow: +formValues?.payingNow > +formValues?.dueAmount || (formValues?.payingNow && +formValues?.payingNow <= 0), //payingNow amount should be greater than 0
      };
    });
    handleIsFormReadyState()

  }, [formValues]);

  useEffect(() => {
    setFormValues((state) => {
      return { ...state, dueAmount: roundedOffDueAmount, ctaValue: currentPlan, ...(userDetails?.subscription_status === 'PAID' ? { current_plan_discounted_price } : { current_plan_discounted_price: 0 }) };
    });
  }, [roundedOffDueAmount, currentPlan?.value, current_plan_discounted_price, userDetails]);
  const getPaymentCalc = async (formValues) => {
    const payload = {
      ...formValues,
    };
    if (formValues.amount == "") {
      return;
    }
    if (formValues.amount >= 0) {
      try {
        setLoading(true);
        const res = await SuperSaverDashboardService.getPaymentCalc({
          user_id,
          data: payload,
        });

        const {
          consumed_coins,
          discount_amount = 0,
          purchase_amount,
        } = res?.data || {};
        setFormValues((state) => ({
          ...state,
          consumed_coins,
          discount_amount,
          amount: purchase_amount || state?.amount,
        }));
        setLoading(false);
      } catch (error) {
        toast.error(error?.messages || "Oops something went wrong");
        setLoading(false);
      }
    }
  };

  const handleStep = (step) => {
    if (step) {
      setStep(step);
      return;
    }
    setStep((step) => step + 1);
  };

  if (!showLinkGenerationForm) return null;

  const PopupWrapperProps = {
    popContentStyle: {},
    toggleBackdrop,
    style: {
      width: dim._scale(470),
      margin: "0",
      height: "auto",
      paddingBottom: dim._scale(24),
    },
  };

  const initialPaymentFormProps = {
    isDue,
    dueAmountInputArr,
    renderInputArr,
    setFormValues,
    formValues,
    getPaymentCalc,
    formErrors,
    isFormReady,
    handleStep,
    user_id,
    setPaymentLink,
    loading,
    currentPlan,
    isFreemium,
    userDetails,
  };


  const handleUiRender = (step) => {
    if (step === 1) {
      return <InitialPaymentForm {...initialPaymentFormProps}
        formValues={formValues}
      />
    }
    if (step === 2) {
      return (
        <ConfirmationContent
          handleStep={handleStep}
          formValues={formValues}
          user_id={user_id}
          setPaymentLink={setPaymentLink}
        />
      );
    }
    if (step === 3) {
      return <PaymentLinkGenerated PaymentLink={PaymentLink} />;
    }
  };

  return (
    <PopupWrapper {...PopupWrapperProps}>{handleUiRender(step)}</PopupWrapper>
  );
};

export default PaymentForm;
