import { USER_ROLE } from "../../constants/userRole";

function getAdminItems(){
  return [
    {
      id: 2,
      name: "Onboard Advisors",
      pathName: "onboard-advisors",
      userType: USER_ROLE.ADMIN,
      active: ["/onboard-advisors"],
    },
    {
      id: 4,
      name: "CSR Assignment",
      pathName: "assign-csr",
      userType: USER_ROLE.ADMIN,
      active: ["/assign-csr"],
    },
    {
      id: 4,
      name: "Bulk Advisor Assignment",
      pathName: "bulk-advisor-assignment",
      userType: USER_ROLE.ADMIN,
      active: ["/bulk-advisor-assignment"],
    },
    {
      id: 3,
      name: "Manage Advisors",
      pathName: "manage-advisors",
      userType: USER_ROLE.ADMIN,
      active: ["/manage-advisors", "/updateAdvisor"],
    },
    {
      id: 4,
      name: "Super Saver Bookings",
      pathName: "super-saver-bookings",
      userType:USER_ROLE.ADMIN,
      active: ["/super-saver-bookings", "/super-saver-bookings/users"],
    },
  
    {
      id: 5,
      name: "Super Saver Users",
      pathName: "",
      isActive:true,
      active: [
        "/superSaverUserProfile",
        "/",
        "/super-saver-user-profile/onboarding-form",
        "/superSaverUserProfile/exemption-page",
        `/superSaverUserProfile/incomeView`,
        `/superSaverUserProfile/incomeView/capital-gain`,
        `/superSaverUserProfile/incomeView/salary`,
        `/superSaverUserProfile/incomeView/business-and-profession`,
        `/superSaverUserProfile/incomeView/digital-asset`,
        `/superSaverUserProfile/incomeView/income-from-house-rent`,
        `/superSaverUserProfile/deductionView`,
        `/superSaverUserProfile/pending-advisorDoc`,
        `/superSaverUserProfile/exemption-page`,
        `/super-saver-user-profile/onboarding-form`,
        `/manage-appointment`,
        `/consultation-experience`,
        `/super-saver-user-profile/itr-filing`
      ],
      userType: USER_ROLE.ADMIN,
    },
    {
      id: 9,
      name: "rent-agreement",
      pathName: "rent-agreement",
      userType: USER_ROLE.ADMIN,
      active: ["/rent-agreement", "/rent-agreement/details"],
    },
    {
      id: 10,
      name: "Chat Dashboard",
      pathName: "dashboard",
      userType: USER_ROLE.ADMIN,
      active: ["/dashboard", "/dashboard/advisor-chat"],
    },
    {
      id: 10,
      name: "Task Manager",
      pathName: "task-manager",
      userType:USER_ROLE.ADMIN,
      active: ["/task-manager"],
    },
    {
      id: 11,
      name: "payslip-manual-review",
      pathName: "payslip-manual-review",
      userType: USER_ROLE.ADMIN,
      active: ["/payslip-manual-review"],
    },
    {
      id: 11,
      name: "ITR Filing Users",
      pathName: "itr-filing-users",
      userType: USER_ROLE.ADMIN,
      active: ["/itr-filing-users"],
    },
  ]
}

function getAuthorItems(){
  return [
    {
      id: 8,
      name: "Articles & Blogs",
      pathName: "articles",
      userType: USER_ROLE.AUTHOR,
      active: ["/articles", "/editor", "/"],
    },
  ]
}

function getAccountantItems(){
  return [
    {
      id: 5,
      name: "Super Saver Users",
      pathName: "",
      active: [
        "/superSaverUserProfile",
        "/",
      ],
      userType: USER_ROLE.ACCOUNTANT,
    },
    {
      id: 9,
      name: "rent-agreement",
      pathName: "rent-agreement",
      userType: USER_ROLE.ACCOUNTANT,
      active: ["/rent-agreement", "/rent-agreement/details"],
    },
  ]
}

function getAdvisorItems(){
  return [
    {
      id: 1,
      name: "Dashboard",
      pathName: "",
      userType: USER_ROLE.ADVISOR,
      active: [
        "/",
      ],
    },
    {
      id: 6,
      name: "Assigned Users",
      pathName: "users",
      userType: USER_ROLE.ADVISOR,
      active: [
        "/userProfileForAdvisor/pending-advisorDoc",
        "/userProfileForAdvisor/deductionView",
        "/userProfileForAdvisor/incomeView",
        "/userProfileForAdvisor",
        "/userProfile-for-advisor/onboarding-form",
        "/userProfileForAdvisor/exemption-page",
        "/users",
        `/userProfileForAdvisor`,
        `/userProfileForAdvisor/incomeView`,
        `/userProfileForAdvisor/incomeView/capital-gain`,
        `/userProfileForAdvisor/incomeView/salary`,
        `/userProfileForAdvisor/incomeView/business-and-profession`,
        `/userProfileForAdvisor/incomeView/digital-asset`,
        `/userProfileForAdvisor/incomeView/income-from-house-rent`,
        `/userProfileForAdvisor/deductionView`,
        `/userProfileForAdvisor/exemption-page`,
        `/userProfileForAdvisor/pending-advisorDoc`,
        `/userProfile-for-advisor/onboarding-form`,
        `/userProfile-for-advisor/client-chat`,
        `/userProfile-for-advisor/user-activity`,
        `/consultation-experience`,
        `/userProfile-for-advisor/itr-filing`,
      ],
    },
    {
      id: 7,
      name: "Active Chats",
      pathName: "active-chats",
      userType: USER_ROLE.ADVISOR,
      active: ["/active-chats"],
    },
    {
      id: 11,
      name: "payslip-manual-review",
      pathName: "payslip-manual-review",
      userType: USER_ROLE.ADVISOR,
      active: ["/payslip-manual-review"],
    },
    {
      id: 10,
      name: "Task Manager",
      pathName: "task-manager",
      userType: USER_ROLE.ADVISOR,
      active: ["/task-manager"],
    },
    {
      id: 11,
      name: "ITR Filing Users",
      pathName: "itr-filing-users",
      userType: USER_ROLE.ADVISOR,
      active: ["/itr-filing-users"],
    },
  ]
}

function getExecutiveItems(){
  return [
    {
      id: 11,
      name: "ITR Filing Users",
      pathName: "itr-filing-users",
      userType: USER_ROLE.OPERATION_EXECUTIVE,
      active: ["/itr-filing-users"],
    },
    {
      id: 11,
      name: "payslip-manual-review",
      pathName: "payslip-manual-review",
      userType: USER_ROLE.OPERATION_EXECUTIVE,
      active: ["/payslip-manual-review"],
    },
    {
      id: 10,
      name: "Task Manager",
      pathName: "task-manager",
      userType: USER_ROLE.OPERATION_EXECUTIVE,
      active: ["/task-manager","/rent-agreement/details"],
    }, 
    {
      id: 10,
      name: "Chat Dashboard",
      pathName: "dashboard",
      userType: USER_ROLE.OPERATION_EXECUTIVE,
      active: ["/dashboard", "/dashboard/advisor-chat/:id"],
    },
    {
      id: 5,
      name: "Super Saver Users",
      pathName: "",
      active: [
        "/superSaverUserProfile",
        "/",
        "/super-saver-user-profile/onboarding-form",
        "/superSaverUserProfile/exemption-page",
        `/superSaverUserProfile/incomeView`,
        `/superSaverUserProfile/incomeView/capital-gain`,
        `/superSaverUserProfile/incomeView/salary`,
        `/superSaverUserProfile/incomeView/business-and-profession`,
        `/superSaverUserProfile/incomeView/digital-asset`,
        `/superSaverUserProfile/incomeView/income-from-house-rent`,
        `/superSaverUserProfile/deductionView`,
        `/superSaverUserProfile/pending-advisorDoc`,
        `/superSaverUserProfile/exemption-page`,
        `/super-saver-user-profile/onboarding-form`,
        `/manage-appointment`,
        `/consultation-experience`,
        `/super-saver-user-profile/itr-filing`
      ],
      userType: USER_ROLE.OPERATION_EXECUTIVE,
    },
  ]
}

const sidebarItems = ( userType ) => {

  if( userType === USER_ROLE.ADMIN){
    return getAdminItems();
  }

  if( userType === USER_ROLE.AUTHOR){
    return getAuthorItems();
  }

  if( userType === USER_ROLE.ACCOUNTANT){
    return getAccountantItems();
  }

  if( userType === USER_ROLE.ADVISOR){
    return getAdvisorItems();
  }

  if( userType === USER_ROLE.OPERATION_EXECUTIVE){
    return getExecutiveItems();
  }

  return [];
}
export { sidebarItems } ;

export const getArticleCategories = [
  {
    id: 1,
    name: "Individual Taxation",
    pathName: "individual-taxation",
  },
  {
    id: 2,
    name: "Fintech Update",
    pathName: "fintech-update",
  },
  {
    id: 3,
    name: "Prosperr Fbp",
    pathName: "prosperr-fbp",
  },
  {
    id: 4,
    name: "Prosperr super saver",
    pathName: "prosperr-super-saver",
  },
  {
    id: 5,
    name: "Image Link generation",
    pathName: "generate-image-link",
  }
];
