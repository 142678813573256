import React from 'react'
import {TextField} from "@mui/material";

const InputField = ({
    data,
    handleFormValueChange,
    formErrors,
    index,
    disabled = false,
  }) => {
    return (
      <TextField
        id={index}
        key={index}
        InputLabelProps={{
          shrink: data?.value || data?.value === 0 || data.type === "date",
        }}
        className="standard-basic"
        label={data.label}
        type={data.type}
        variant="standard"
        name={data.name}
        value={data?.value}
        onChange={(e) => {
          handleFormValueChange(e);
        }}
        disabled={disabled}
        error={formErrors[data.name]}
        helperText={
          formErrors[data.name] && (data?.errMsg || `Invalid ${data.name}`)
        }
        onWheel={(e) => {
          e.target.blur();
        }}
      />
    );
  };

export default InputField