import React, { useState, useEffect, useContext } from "react";
import FlexRow from "../FlexRow/FlexRow";
import Button from "../Button";
import AdvisorContext from "../../Context/Advisor/AdvisorContext";
import TimeSlot from "../TimeSlot/TimeSlot";
import CardAddSlot from "../CardAddSlot/CardAddSlot";
import HeadingH1 from "../HeadingH1/HeadingH1";
import AdvisorTable from "../AdvisorTable/AdvisorTable";
import AppointmentTable from "../AdvisorTable/AppointmentTable/index";
import FlexCol from "../FlexCol/FlexCol";
import { addSlot } from "../../Services/Slots";
import Alert1 from "../Alert/Alert1";
import { getAuth } from "@firebase/auth";
import moment from "moment";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import { getAuthToken } from "../../Services/httpUtil";
import { PosthogActions } from "../../Integrations/Posthog/constant";
import posthog from "posthog-js";

const AdvisorProfile = ({ data, UpdateSlot, Id, token }) => {
  const [Time, setTime] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [ShowAlert, setShowAlert] = useState(false);
  const [SubmitMsg, setSubmitMsg] = useState("");
  const [loader, setLoader] = useState(false);
  const [dateSelected, setdateSelected] = useState([
    moment().format("YYYY-MM-DD"),
  ]);
  const [purpose, setPurpose] = useState({
    value: "SLOT_CREATION",
    label: "Create Slot For Advisor Booking",
  });

  const [arrayData, setArrayData] = useState(data);
  const [rangeValue, setrangeValue] = useState(500);
  const [DiscountValue, setDiscountValue] = useState(10);
  const [DiscountedFee, setDiscountedFee] = useState(
    Math.round(rangeValue - (DiscountValue * rangeValue) / 100)
  );

  const a = useContext(AdvisorContext);
  const navigate = useNavigate();
  // use Effect for Get Advisor data from props
  useEffect(() => {
    if (a.ResetTime) {
      a.setResetTime(false);
    }
  }, [a]);

  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push(new Date(dt));
    }
    return arr;
  };

  const handlePurpose = (value) => {
    setPurpose(value);
  };

  const handleSubmit = () => {
    if (
      rangeValue !== "" &&
      Number(rangeValue) >= 0 &&
      DiscountValue !== "" &&
      Number(DiscountValue) >= 0 &&
      DiscountedFee !== "" &&
      Number(DiscountedFee) >= 0
    ) {
      setLoader(true);
      getAuthToken()
        .then(function (idToken) {
          return addSlot(
            arrayData.user_details.external_id,

            dateSelected,
            startTime,
            endTime,
            a.username,
            rangeValue,
            DiscountedFee,
            DiscountValue,
            a,
            idToken,
            purpose
          );
        })
        .then((response) => {
          if (Array.isArray(response)) {
            a.setResetTime(true);
            setShowAlert(true);
            setSubmitMsg("Slot Added");
            setTimeout(() => {
              setShowAlert(false);
            }, 1000);
            setLoader(false);
            setArrayData((pre)=>({...pre,isCreateSlotActive:true}))
            a.setTf(true)
          } else {
            toast.error(response.message);
            setLoader(false);
          }

          posthog.capture(PosthogActions.SLOT_CREATED, {purpose,dateSelected});

          return response;
        })
        .catch((e) => {
          setLoader(false);
        });
    }
  };
  return (
    <div className="">
      <Alert1 ShowAlert={ShowAlert} SubmitMsg={SubmitMsg} mid={true} />
      <FlexRow>
        <img
          style={{ width: "152px", height: "152px", borderRadius: "50%" }}
          src={arrayData.user_details.image}
          alt=""
        />
        <div style={{ width: "300px" }} className="">
          <FlexRow>
            <table style={{ width: "100%" }}>
              {/* <tr>
                <th>Advisor id:</th>
                <td>{arrayData.id}</td>
              </tr> */}
              <tr>
                <th>Full Name:</th>
                <td>
                  {arrayData.user_details.first_name}{" "}
                  {arrayData.user_details.last_name}
                </td>
              </tr>
              <tr>
                <th>Mobile:</th>
                <td>{arrayData.user_details.mobile}</td>
              </tr>

              <tr>
                <th>Experience:</th>
                <td>{arrayData.experience}</td>
              </tr>
              <tr>
                <th>Location:</th>
                <td>{arrayData.address_details.city}</td>
              </tr>
            </table>
          </FlexRow>
        </div>

        <FlexCol>
          <Button
            buttonClassName={"Slotbtn"}
            onButtonClick={() => {
              a.setTf(true);
              a.setUpdateAdv(true);
              navigate("/updateAdvisor", {
                state: { number: arrayData.user_details.mobile },
              });
            }}
            buttonName="Update Advisor"
          />
          <br />
          <Button
            buttonClassName={arrayData.isActive ? "btnactiveslot" : "Slotbtn"}
            onButtonClick={() => {
              a.setTf(true);
              if (
                arrayData.isActive ||
                arrayData.isSlotsActive ||
                arrayData.isCreateSlotActive ||
                arrayData.isAppointmentsActive
              ) {
                if (arrayData.isSlotsActive) {
                  setArrayData({
                    ...arrayData,
                    isSlotsActive: false,
                    isCreateSlotActive: false,
                    isAppointmentsActive: false,
                    isActive: false,
                  });
                } else {
                  setArrayData({
                    ...arrayData,
                    isSlotsActive: false,
                    isCreateSlotActive: false,
                    isAppointmentsActive: false,
                    isActive: false,
                  });
                }
              } else {
                if (arrayData.isSlotsActive) {
                  setArrayData({
                    ...arrayData,
                    isSlotsActive: true,
                    isCreateSlotActive: false,
                    isAppointmentsActive: false,
                    isActive: true,
                  });
                } else {
                  setArrayData({
                    ...arrayData,
                    isSlotsActive: true,
                    isCreateSlotActive: false,
                    isAppointmentsActive: false,
                    isActive: true,
                  });
                }
              }
            }}
            buttonName="Consultation"
          />
          {/* <Button buttonClassName={"Slotbtn"} buttonName="Assigned Users" /> */}
        </FlexCol>
      </FlexRow>
      <br />
      {arrayData.isActive ? (
        <div className="">
          <hr />
          <FlexRow>
            <Button
              buttonClassName={
                arrayData.isCreateSlotActive ? "btnactiveslot" : "Slotbtn"
              }
              onButtonClick={() => {
                a.setTf(true);
                if (
                  arrayData.isActive ||
                  arrayData.isSlotsActive ||
                  arrayData.isCreateSlotActive ||
                  arrayData.isAppointmentsActive
                ) {
                  if (arrayData.isCreateSlotActive) {
                    setArrayData({
                      ...arrayData,
                      isCreateSlotActive: false,
                      isAppointmentsActive: false,
                      isSlotsActive: false,
                      isActive: false,
                    });
                  } else {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: false,
                      isCreateSlotActive: true,
                      isAppointmentsActive: false,
                      isActive: true,
                    });
                  }
                } else {
                  if (arrayData.isCreateSlotActive) {
                    setArrayData({
                      ...arrayData,
                      isCreateSlotActive: false,
                      isAppointmentsActive: false,
                      isSlotsActive: false,
                      isActive: true,
                    });
                  } else {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: false,
                      isCreateSlotActive: true,
                      isAppointmentsActive: false,
                      isActive: true,
                    });
                  }
                }
              }}
              buttonName="Create Slot"
            />
            <br />
            <Button
              buttonClassName={
                arrayData.isSlotsActive ? "btnactiveslot" : "Slotbtn"
              }
              onButtonClick={() => {
                a.setTf(true);
                if (
                  arrayData.isActive ||
                  arrayData.isSlotsActive ||
                  arrayData.isCreateSlotActive ||
                  arrayData.isAppointmentsActive
                ) {
                  if (arrayData.isSlotsActive) {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: false,
                      isCreateSlotActive: false,
                      isAppointmentsActive: false,
                      isActive: false,
                    });
                  } else {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: true,
                      isCreateSlotActive: false,
                      isAppointmentsActive: false,
                      isActive: true,
                    });
                  }
                } else {
                  if (arrayData.isSlotsActive) {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: true,
                      isCreateSlotActive: false,
                      isAppointmentsActive: false,
                      isActive: true,
                    });
                  } else {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: true,
                      isCreateSlotActive: false,
                      isAppointmentsActive: false,
                      isActive: true,
                    });
                  }
                }
              }}
              buttonName="View Slots"
            />
            <br />

            <Button
              buttonClassName={
                arrayData.isAppointmentsActive ? "btnactiveslot" : "Slotbtn"
              }
              onButtonClick={() => {
                a.setDelete(true);
                if (
                  arrayData.isActive ||
                  arrayData.isSlotsActive ||
                  arrayData.isCreateSlotActive ||
                  arrayData.isAppointmentsActive
                ) {
                  if (arrayData.isAppointmentsActive) {
                    setArrayData({
                      ...arrayData,
                      isAppointmentsActive: false,
                      isSlotsActive: false,
                      isCreateSlotActive: false,
                      isActive: false,
                    });
                  } else {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: false,
                      isCreateSlotActive: false,
                      isAppointmentsActive: true,
                      isActive: true,
                    });
                  }
                } else {
                  if (arrayData.isAppointmentsActive) {
                    setArrayData({
                      ...arrayData,
                      isAppointmentsActive: true,
                      isSlotsActive: false,
                      isCreateSlotActive: false,
                      isActive: true,
                    });
                  } else {
                    setArrayData({
                      ...arrayData,
                      isSlotsActive: false,
                      isCreateSlotActive: false,
                      isAppointmentsActive: true,
                      isActive: true,
                    });
                  }
                }
              }}
              buttonName={UpdateSlot === true ? "Get Slot" : "Appointsments"}
            />
          </FlexRow>
          <br />

          <div className="">
            {/* create slots */}
            {arrayData.isCreateSlotActive ? (
              <div className="" style={{ marginTop: "20px" }}>
                <HeadingH1>Create Time Slot</HeadingH1>
                <CardAddSlot>
                  <TimeSlot
                    Id={arrayData.user_details.external_id}
                    arrayData={false}
                    array={arrayData}
                    setEndTime={setEndTime}
                    setStartTime={setStartTime}
                    setdateSelected={(value) => {
                      setdateSelected(
                        value.map((d) =>
                          moment(new Date(d)).format("YYYY-MM-DD")
                        )
                      );
                    }}
                    TimeSlotdata={(value) => {
                      setTime(value);
                    }}
                    handlePurpose={handlePurpose}
                    purpose={purpose}
                  />
                </CardAddSlot>
    
                <div className="" style={{ marginTop: "20px" }}>
                  <HeadingH1>Consultation fee</HeadingH1>
                  <CardAddSlot>
                    <FlexRow>
                      <div className="">
                        <label>
                          {" "}
                          <b> Price (Rs): &nbsp;</b>
                          <input
                            style={{
                              height: "30px",
                              width: "108px",
                              lineHeight: "1.5",
                              color: "#666",
                              backgroundColor: "#fff",
                              backgroundImage: "none",
                              border: "1px solid #d9d9d9",
                              borderRadius: "4px",
                              fontSize: " 16px",
                              cursor: "pointer",
                            }}
                            type="number"
                            value={rangeValue}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              setrangeValue(e.target.value);
                              setDiscountedFee(
                                Math.round(
                                  e.target.value -
                                    (DiscountValue * e.target.value) / 100
                                )
                              );
                            }}
                            name=""
                            id=""
                          />
                        </label>
                      </div>

                      <div className="">
                        <label>
                          {" "}
                          <b>Discount (%): &nbsp;</b>
                          <input
                            style={{
                              height: "30px",
                              width: "108px",
                              lineHeight: "1.5",
                              color: "#666",
                              backgroundColor: "#fff",
                              backgroundImage: "none",
                              border: "1px solid #d9d9d9",
                              borderRadius: "4px",
                              fontSize: " 16px",
                              cursor: "pointer",
                            }}
                            type="number"
                            value={DiscountValue}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => {
                              if (e.target.value >= 100) {
                                setDiscountValue(100);
                                setDiscountedFee(
                                  Math.round(
                                    rangeValue - (100 * rangeValue) / 100
                                  )
                                );
                              } else {
                                setDiscountValue(e.target.value);
                                setDiscountedFee(
                                  Math.round(
                                    rangeValue -
                                      (e.target.value * rangeValue) / 100
                                  )
                                );
                              }
                            }}
                            name=""
                            id=""
                          />
                        </label>
                      </div>
                      <label className="">
                        <b> Discounted Fee (Rs):</b>

                        <input
                          style={{
                            height: "30px",
                            width: "108px",
                            lineHeight: "1.5",
                            color: "#666",
                            backgroundColor: "#fff",
                            backgroundImage: "none",
                            border: "1px solid #d9d9d9",
                            borderRadius: "4px",
                            fontSize: " 16px",
                            cursor: "pointer",
                          }}
                          type="number"
                          value={DiscountedFee}
                          onWheel={(e) => e.target.blur()}
                          onChange={(e) => {
                            if (Number(e.target.value) >= Number(rangeValue)) {
                              setDiscountedFee(rangeValue);
                              setDiscountValue(
                                Math.round(
                                  0

                                  // 100 * (rangeValue - rangeValue) / rangeValue
                                )
                              );
                            } else {
                              setDiscountedFee(e.target.value);
                              setDiscountValue(
                                Math.round(
                                  (100 * (rangeValue - e.target.value)) /
                                    rangeValue
                                )
                              );
                            }
                          }}
                          name=""
                          id=""
                        />
                      </label>
                    </FlexRow>
                  </CardAddSlot>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      onButtonClick={loader ? () => {} : handleSubmit}
                      buttonClassName={
                        (rangeValue !== "" &&
                          Number(rangeValue) >= 0 &&
                          DiscountValue !== "" &&
                          Number(DiscountValue) >= 0 &&
                          DiscountedFee !== "" &&
                          Number(DiscountedFee) >= 0) ||
                        !loader
                          ? "Slotbtn"
                          : "btnhide"
                      }
                      buttonName="Submit"
                    />
                  </div>
                  <br />
                  <hr />
                </div>
                <div className="">
                  <HeadingH1>Time Slots </HeadingH1>
                  <CardAddSlot>
                    <AdvisorTable
                      arrayData={arrayData}
                      UpdateSlot={UpdateSlot}
                      Id={arrayData.user_details.external_id}
                      Time={Time}
                      token={token}
                    />
                  </CardAddSlot>
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* view slots */}
            {arrayData.isSlotsActive || arrayData.isCreateSlotActives ? (
              <div className="">
                <HeadingH1>Slots </HeadingH1>
                <CardAddSlot>
                  <AdvisorTable
                    arrayData={arrayData}
                    UpdateSlot={UpdateSlot}
                    Id={arrayData.user_details.external_id}
                    Time={Time}
                    token={token}
                  />
                </CardAddSlot>
              </div>
            ) : (
              <></>
            )}

            {/* appointments */}
            {arrayData.isAppointmentsActive ? (
              <div className="" style={{ marginTop: "20px" }}>
                <div className="">
                  <HeadingH1>Appointments{"   "}</HeadingH1>
                  <CardAddSlot>
                    <AppointmentTable
                      arrayData={arrayData}
                      Id={arrayData.user_details.external_id}
                      Time={Time}
                      token={token}
                    />
                  </CardAddSlot>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default AdvisorProfile;
