import {useDispatch} from "react-redux";
import {RemoteConfigActions} from "./GetRemoteConfigActions";
import {fetchRemoteConfigValues, getRemoteConfigValue} from "./config";
import RemoteConfigKeys from "./constant";
import {useEffect} from "react";

export const useRemoteConfig = () => {
  const dispatch = useDispatch();
  const fetchConfig = async () => {
    await fetchRemoteConfigValues();

    const ProdDashboardPaymentV2FeatureFlagCMS = getRemoteConfigValue(
      RemoteConfigKeys.PROD_DASHBOARD_PAYMENT_V2_FEATURE_FLAG_CMS
    );
    const RentAgreementFeatureFlag = getRemoteConfigValue(
      RemoteConfigKeys.Rent_Agreement_feature_Flag
    );

    let remoteData = {
      ProdDashboardPaymentV2FeatureFlagCMS,
      RentAgreementFeatureFlag
    };
    dispatch({
      type: RemoteConfigActions.SAVE_REMOTE_CONFIG_VALUES,
      data: remoteData,
    });
  };

  useEffect(() => {
    fetchConfig();
  }, []);
};
