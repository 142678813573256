import { useEffect, useState } from "react";
import SuperSaverPlans from "../../../Services/SuperSaverPlans";

const SUPER_SAVER = "Super Saver";

export const useSSPlan = (
  handleDefaultValue,
  currentPlan,
  isFreemium = true
) => {
  const { current_plan_discounted_price = null } = currentPlan || {};
  const [loading, setLoading] = useState(false);
  const [planOptions, setPlanOptions] = useState([]);
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const allPlans = await SuperSaverPlans.getAllPlans();
        const SSPlans =
          allPlans?.data
            ?.filter((data) => {
              return data.name === SUPER_SAVER;
            })[0]
            ?.category_details?.sort((a, b) => {
              return a?.discounted_price - b?.discounted_price;
            }) || [];
        if (SSPlans.length > 0) {
          const modifiedSSPlans = SSPlans?.filter((plan) => {
            if (!isFreemium) {
              return plan.discounted_price > current_plan_discounted_price;
            }
            return plan;
          }).map((d) => {
            return {
              ...d,
              value: d?.category_id,
              label: d?.name,
            };
          });
          const findingDefaultValue = () => {
            if (current_plan_discounted_price > 0) {
              const currentPlanIndex = modifiedSSPlans?.findIndex((plan) => plan?.name === currentPlan?.label)
              return modifiedSSPlans[currentPlanIndex + 1] || modifiedSSPlans[modifiedSSPlans.length - 1]
            }
            else {
              const currentPlanIndex=SSPlans?.findIndex((plan)=>plan?.name===currentPlan?.label)
              return SSPlans[currentPlanIndex + 1] || SSPlans[SSPlans.length - 1]
            }
          }
          const defaultValue = findingDefaultValue()


          setPlanOptions(modifiedSSPlans);
          handleDefaultValue(defaultValue);
          setLoading(false);
        } else {
          setLoading(false);
          return false;
        }
      } catch (error) {
        setLoading(false);
      }
    })();
  }, [current_plan_discounted_price]);

  return [planOptions, loading];
};
