import styled from "styled-components";
import { dim } from "../../styles/theme";

export const StyledTaskManager = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0 0px 0 0;
  box-sizing: border-box;
  .resultS {
    width: 100%;
    box-sizing: border-box;
  }
  .background {
    background-color: red;
    height: 52px;
    position: absolute;
    top: -16px;
    left: -10px;
    width: 16px;
  }
  .userName {
    padding-left: 12px;
  }
  .MuiIconButton-sizeSmall {
    width: 40px !important;
    height: 40px !important;
  }
  .css-1s0hp0k-MuiDataGrid-columnHeadersInner {
    font-size: large;
    font-weight: 500;
  }
  .toggle{
    margin-top: ${dim._8px};
    margin-bottom: ${dim._8px};
  }
  .filter-style{
  width:100%;
  ${(props)=>props?.isActionItemPending? `justify-content:space-between;`:`justify-content:flex-end;`}
  }
  .filter-between {
  width:100%;
  justify-content:space-between;
  }
  .filter-grid {
  display:grid;
   ${(props)=>props?.isCsrTask? ` grid-template-columns: auto auto;`:` grid-template-columns: auto;`}
   
  }
  .task-search-bar{
  width:100%;
  display:flex;
  justify-content:flex-end;
  }

`;
