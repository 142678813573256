import styled from "styled-components";
import { StyledPlanValueForm } from "../../components/UserPlanDegradation/PlanValueForm/PlanValueForm.styled";
import { dim } from "../../styles/theme";

export const StyledRegisterSSUserForm=styled(StyledPlanValueForm)`
.input{
    width: auto;
    margin: 0;
    padding: ${dim._24px} 0 ${dim._10px};
}
#createUserCTA{
    height: auto;
    margin-top: ${dim._24px};
    margin-left: ${dim._24px};
    margin-right: ${dim._24px};
}
.popContent{
    padding-top: ${dim._24px};
    margin: 0px ${dim._20px};
    text-align: start;
}
.paymentWrapper{
    width:100%;
    margin-left: ${dim._24px};
    margin-right: ${dim._24px};
}
.dropdown-plans{
    width:100%;
}
.closePop{
    z-index: 2;
}
`;
