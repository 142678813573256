import Styled from "styled-components";

export const TableContainer = Styled.div`
  position: relative; /* To position the loader absolutely */
`;

export const LoaderOverlay = Styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensures it is above the table */

  .loader-icon{
  padding: 5px 0px;
  height:36px;
  width:36px;
}
`;
export const Table = Styled.table`
  border-collapse: collapse;
  width: 100%;
  position: relative; /* Needed for proper stacking */
  opacity: ${(props) => (props.loading ? "0.5" : "1")};

/* table { */
.td{border: 1px solid #dddddd;
 min-width:100px;
  text-align: center;
  padding: 8px;
  box-sizing:border-box; 
  }
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  /* margin-top:5%; */
/* }

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
} */



`;
export const Th = Styled.th`
 border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
`;
export const Td = Styled.td`
/* display: flex; */
/* justify-content:space-between; */
 border: 1px solid #dddddd;
 min-width:140px;
  text-align: left;
  padding: 8px;
  box-sizing:border-box;
  /* display:flex; */
  /* justify-content:space-between; */
`;
export const Tr = Styled.tr`
.icon{
  display:inline-block;
  display:flex;
flex-direction:row;
justify-content:flex-end;}
color:${(props) =>
  props.flag === "BOOKED" || props.flag === "BLOCKED"
    ? "red"
    : "rgb(11, 106, 176)"};
color:${(props) =>
  props.appointment_status === "CANCELED" ? "rgba(11, 106, 176, 0.4)" : ""};
/* ${(props) => (props.flag === "BOOKED" ? "red" : "black")} */
&:nth-child(even)
{background-color:rgba(11, 106, 176, 0.16);}

`;
