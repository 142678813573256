export let MOBILE_KEY = "mobile"
export const tableFilterKeyType={
    STRING:"STRING",
    ARRAY:"ARRAY"
}
export const options = [
    {
        value: 'name',
        label: 'Name',
        objectKey: "user_response.first_name",
        type:tableFilterKeyType.STRING
    },
    {
        value: MOBILE_KEY,
        label: 'Mobile',
        objectKey: "user_response.mobile",
        type:tableFilterKeyType.STRING
    },
    {
        value: 'email',
        label: 'Email',
        objectKey: "user_response.email",
        type:tableFilterKeyType.STRING
    },
    {
        value: 'csr_name',
        label: 'CSR Name',
        objectKey: "csr_user.name",
        type:tableFilterKeyType.STRING
    },
    {
        value: 'plan_name',
        label: 'Plan Name',
        objectKey: "category_type",
        type:tableFilterKeyType.STRING
    },
    {
      value: "advisor_name",
      label: "Advisor Name",
      objectKey:["advisor_details.first_name","advisor_details.middle_name","advisor_details.last_name"],
      type:tableFilterKeyType.STRING
    }
];
export const csrTaskTableOptions = [
    { value: "name", label: "Name", objectKey: "user.first_name",type:tableFilterKeyType.STRING },
    { value: "email", label: "Email", objectKey: "user.email",type:tableFilterKeyType.STRING },
    { value: "title", label: "Title", objectKey: "task_detail.title",type:tableFilterKeyType.STRING },
    { value: "tags", label: "Tags", objectKey: "task_detail.tags",type:tableFilterKeyType.ARRAY },
    { value: "created", label: "Created By", objectKey: "created_by.first_name",type:tableFilterKeyType.STRING },
]
export const csrNameOptionForCsrTask =  {value:"csr_name",label:"CSR Name",objectKey:"csr_details",type:tableFilterKeyType.ARRAY,nested_key:"name"}

export const getNestedValueFromObject = (obj, keyPath,type=tableFilterKeyType?.STRING,nested_key="") => {
    if(type===tableFilterKeyType.STRING){
        if (typeof keyPath === "string") {
            return keyPath.split('.').reduce((acc, key) => acc?.[key], obj);
        }
        
        if (Array.isArray(keyPath)) {
            return keyPath
                .map(path => path.split('.').reduce((acc, key) => acc?.[key], obj) || "")
                .join(" ")
                .trim();
        }
    } else if(type===tableFilterKeyType.ARRAY){
        const value = keyPath.split('.').reduce((acc, key) => acc?.[key], obj);

        if (Array.isArray(value)) {
            return value
                .map(item => item?.[nested_key] || item?.split("_")?.join(""))
                .filter(Boolean) 
                .join(" ")
                .trim();
        }
        return null;
    }
   

    return null; 
};

