import React from 'react'
import PopupBtn from '../../../../commonComponents/Popup/Popup'
import { ClientDetails } from '../../../SuperClientProfile/SuperSaverProfile.styled'

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditBtn from '../../../../commonComponents/EditBtn';
import { USER_TYPE } from '../../../../utils/localStorageKeys';
import { USER_ROLE } from '../../../../constants/userRole';
import { amountWithGst } from '../../../../utils/getGstAmount';
import { roundNumber } from '../../../../utils/getGstAmount';
const PaymentComponent = ({paymentStatus,handleChange,checked,open,setopen,handleMsg,setChecked,userDetails,userId,handleUserId,getUserDetails}) => {
const {due_amount,version=0}=userDetails?.user_payment_details||{};
const dueAmount= (version===0?due_amount: due_amount/1.18) || 0;
const roundedOffDueAmount = amountWithGst(dueAmount);const userType = JSON.parse(localStorage.getItem(USER_TYPE));
const userRole = userType?.user_roll;



  if (userRole == USER_ROLE.ACCOUNTANT || userRole == USER_ROLE.ADMIN){

  return (
    <>
      <div className="clientBox">
        <div className="ClientHed">
          Payment status {dueAmount > 0 ? `(Payment due)` : null}{" "}
        </div>{" "}
        {dueAmount > 0 ? (
          <ClientDetails className="dueAmount">
            <div className="dueAmountWrapper">
              <div className="dueAmountData">
                <p>Gross Due : </p>
                <p className="grossDue">&#8377;{roundNumber(dueAmount)}</p>
              </div>
              <div className="dueAmountData">
                <p>GST @18% : </p>
                <p>&#8377;{roundNumber(dueAmount * 0.18)}</p>
              </div>
              <div className="dueAmountData totalDue">
                <p className="totalDueAmt">To pay :</p>
                <div>
                  <span>&#8377;{roundedOffDueAmount}</span>
                  {
                    version!=1 &&
                     <EditBtn
                    HandleClick={() => {
                      setopen(true);
                    }}
                  />
                  }
                  
                </div>
              </div>
            </div>
          </ClientDetails>
        ) : (
          <ClientDetails className="ClientDetails">
            <FormControlLabel
              control={
                <Switch
                  checked={paymentStatus}
                  onChange={handleChange}
                  value={paymentStatus}
                />
              }
              label={checked}
            />
          </ClientDetails>
        )}
        <PopupBtn
          pop={false}
          checked={checked}
          open={open}
          setopen={setopen}
          handleMsg={handleMsg}
          setchecked={setChecked}
          email={userDetails?.user_response?.email}
          userId={userId}
          setUserId={handleUserId}
          innerPage={true}
          name={userDetails?.user_response?.first_name}
          mobile={userDetails?.user_response?.mobile}
          category_id={userDetails?.category_id}
          category_type={userDetails?.category_type}
          external_id={userDetails?.category_id}
          actualPlanPrice={userDetails?.current_plan_discounted_price}
          dueAmount={dueAmount}
          getUserDetails={getUserDetails}
        />
      </div>
    </>
  );
}
return null;
}

export default PaymentComponent