import React, { useEffect, useState } from "react";
import { StyledTaskManager } from "./TaskManager.styled";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { getSuperSaverUser } from "../../Services/SuperSaver";
import posthog from "posthog-js";
import { columnsForCsrTaskTab, columnsForTaskManager } from "../../utils/superSaverUser";
import ActionFilter from "../../components/ActionFilter";
import LoaderPage from "../../commonComponents/LoaderPage";
import Toggle from "../../commonComponents/Toggle/Toggle";
import { PosthogActions } from "../../Integrations/Posthog/constant";
import CsrTaskModal from "../../commonComponents/CsrTaskCreator/TaskModal";
import { CsrTaskService } from "../../Services/CsrTaskService";
import toast from "react-hot-toast";
import { getFormattedDate } from "../../utils/timeUtils";
import { USER_ROLE } from "../../constants/userRole";
import { Collapse, Button, Radio, Flex, Grid } from "antd";
import { TASK_STATUS } from "../../commonComponents/CsrTaskCreator/constant";
import SearchBar from "../../commonComponents/SearchBar";
import { csrNameOptionForCsrTask, csrTaskTableOptions, getNestedValueFromObject, MOBILE_KEY, options } from "./utils";
import RentAgreement from "../RentAgreement";
import { useSelector } from "react-redux";


const call_status = {
  CALL_SUMMARY_PENDING: "CALL_SUMMARY_PENDING",
  TOMORROW_CALL: "TOMORROW_CALL",
  TODAY_CALL: "TODAY_CALL",
};

const CSR_TASK = "CSR_TASK";

const tabList = [
  {
    label: "Action Item Pending ",
    value: "ACTION_ITEM_PENDING",
  },
  {
    label: "Pending MOM",
    value: call_status.CALL_SUMMARY_PENDING,
  },
  {
    label: "Today Calls",
    value: call_status.TODAY_CALL,
  },
  {
    label: "Tomorrow Calls",
    value: call_status.TOMORROW_CALL,
  },
  {
    label: "CSR Tasks",
    value: CSR_TASK,
  }
];

const Rent_Agreement = "Rent-Agreement"

const TaskManager = () => {
  const type = JSON.parse(localStorage.getItem("type"));
  const userRole = type?.user_roll;
  const tabList = [
    {
      label: "Action Item Pending ",
      value: "ACTION_ITEM_PENDING",
    },
    {
      label: "Pending MOM",
      value: call_status.CALL_SUMMARY_PENDING,
    },
    {
      label: "Today Calls",
      value: call_status.TODAY_CALL,
    },
    {
      label: "Tomorrow Calls",
      value: call_status.TOMORROW_CALL,
    },
    {
      label: "CSR Tasks",
      value: CSR_TASK,
    },
  ];
  const remoteConfig = useSelector((state) => state?.RemoteConfigReducer);
  const { RentAgreementFeatureFlag } = remoteConfig || {};
  const isRentAgreementFeatureEnabled = JSON.parse(RentAgreementFeatureFlag);
  if (isRentAgreementFeatureEnabled && userRole !== USER_ROLE.ADMIN) {
    tabList.push({
      label: "Rent Agreement",
      value: Rent_Agreement,
    },)
  }
  const selectedView = localStorage.getItem(
    LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE
  );

  const [Data, setData] = useState([]);
  const [tableData, setTableData] = useState([])
  const [value, setValue] = React.useState(
    (tabList.some((d) => d.value === selectedView) &&
      localStorage.getItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE)) ||
    "ACTION_ITEM_PENDING"
  );



  const [loader, setLoader] = useState(true);
  const email = JSON.parse(localStorage.getItem("email"));
  const [actionSelected, setActionSelected] = useState(null);
  const [selectedAdvisorType, setSelectedAdvisorType] = useState(null);
  const [selectedCsrTask, setSelectedCsrTask] = useState({});
  const [isTaskModalVisible, setIsTaskModalVisible] = useState(false);
  const [taskData, setTaskData] = useState([]);
  const [csrTaskData, setCsrTaskData] = useState([])
  const [taskState, setTaskState] = useState(TASK_STATUS.PENDING);
  const [dataRent, setdataRent] = useState([]);

  const handleOnCellClick = (params, navigate) => {
    const user_id = params?.row.user_id;
    posthog.capture(PosthogActions.TASK_MANAGER_USED, {
      user_id,
      userViewType: value,
    });


    navigate(`/consultation-experience?user_id=${user_id}`, {
      state: { item: params.row },
    });
  };

  const handleCsrTaskTabClick = (params) => {
    setSelectedCsrTask(params?.row?.task);
    setIsTaskModalVisible(true);
  }

  const handleTaskStateChange = (value) => {
    setTaskState(value);

  };

  const tabPanelList = [
    {
      value: "ACTION_ITEM_PENDING",
      data: Data,
      columns: columnsForTaskManager,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },

    {
      value: call_status.TODAY_CALL,
      data: Data,
      columns: columnsForTaskManager,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },
    {
      value: call_status.CALL_SUMMARY_PENDING,
      data: Data,
      columns: columnsForTaskManager,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },
    {
      value: call_status.TOMORROW_CALL,
      data: Data,
      columns: columnsForTaskManager,
      handleOnCellClick: handleOnCellClick,
      rowFunction: rowFunction,
      enablePaging: false,
      pathname: "/",
    },
    {
      value: CSR_TASK,
      data: taskData,
      columns: columnsForCsrTaskTab(),
      handleOnCellClick: handleCsrTaskTabClick,
      rowFunction: rowFunctionForCsrTask,
      enablePaging: false,
      pathname: "/",
    }
  ];

  const isAdvisor = userRole === USER_ROLE.ADVISOR;
  const filteredTabList = isAdvisor ? tabList.slice(0, -1) : tabList;

  const handleChange = (event, newValue) => {
    localStorage.setItem(LOCAL_DB_KEYS.SELECTED_USER_VIEW_TYPE, newValue);
    setValue(newValue);
  };

  function rowFunction(value, index, array) {
    const { referral_code, csr_user, user_response, start_time } = value;
    const { name: csrName } = csr_user || {};
    const { mobile } = user_response || {};
    return {
      id: index,
      subscription_status: value?.subscription_status
        ? value?.subscription_status
        : "",
      referral_code: referral_code,
      name: value?.user_response?.first_name,
      service_type: value?.category_type,
      targetTax: value?.targeted_tax,
      super_saver_booking_id: value?.external_id,
      service_id: value?.category_id,
      email: value?.user_response?.email,
      invite_id: value?.invite_id,
      onboard_id: value?.onboard_id,
      user_id: value?.user_id,
      activity_details: value?.activity_details,
      invitation_status: value?.invitation_status,
      advisor: value?.advisor_details
        ? value?.advisor_details?.first_name +
        " " +
        value?.advisor_details?.last_name
        : "Not-Assigned",
      advisor_details: value?.advisor_details,
      appVersion: value?.app_version,
      appVersions:
        value?.meta_data_payload && value?.meta_data_payload?.app_version,
      mobile,
      csrName,
      start_time,
    };
  }

  function rowFunctionForCsrTask(value, index) {
    const csrName = value?.csr_details?.length > 0 ? value?.csr_details[0]?.name : "-";
    return ({
      id: index,
      name: value?.user?.first_name,
      email: value?.user?.email,
      title: value?.task_detail?.title,
      tags: value?.task_detail?.tags?.join(", ").replaceAll("_", " "),
      due_date: getFormattedDate(value?.task_detail?.due_date),
      advisor: value?.created_by?.first_name,
      status: value?.task_status,
      assigned_csr: csrName,
      task: value
    })
  }
  const handleCsrTaskModalClose = () => {
    setIsTaskModalVisible(false);
  }

  const getUserDataAccordingToUserType = (users) => {
    return users
      ?.sort((a, b) => b?.created_at - a?.created_at)
      ?.filter((user, i) => {
        return user;
      });
  };


  const getUserList = async (param, filter, advisor_type) => {
    setLoader(true);
    if (value !== Rent_Agreement) {

      getSuperSaverUser(param, filter, advisor_type).then(function (users) {
        setData(getUserDataAccordingToUserType(users));
        setTableData(getUserDataAccordingToUserType(users));
        setLoader(false);
      });
    }
    else {
      setLoader(false);
    }
  };


  useEffect(() => {
    if (value !== CSR_TASK) {
      getUserList(
        value !== "ALL" && value !== "Expired" ? value : undefined,
        actionSelected,
        selectedAdvisorType
      );
    }
  }, [value, selectedAdvisorType, actionSelected]);

  async function getAllCsrTasks(taskState) {
    try {
      setLoader(true)
      const reqParams = {
        'status': taskState
      }
      const allCsrTasks = await CsrTaskService.getAllTasks(reqParams);
      setTaskData([...allCsrTasks?.data])
      setCsrTaskData([...allCsrTasks?.data])
    }
    catch (e) {
      toast.error(e)
    }
    finally {
      setLoader(false)
    }
  }

  const filterDataByKeyValue = (key, value, options) => {
    const filteredData = [];
    const object = options?.filter((each) => each?.value === key)[0] || {}

    if (!value || value?.trim() === "") {
      setData(tableData);
      return
    };

    const searchValue = key === MOBILE_KEY
      ? value.replace(/\s+/g, "")
      : value.replace(/\s+/g, "").toLowerCase();
    tableData.forEach(item => {
      let itemValue = key === MOBILE_KEY ? getNestedValueFromObject(item, object?.objectKey)?.replace(/\s+/g, "") : getNestedValueFromObject(item, object?.objectKey)?.replace(/\s+/g, "")?.toLowerCase();
      if (itemValue?.includes(searchValue)) {
        filteredData.push(item);
      }
    });
    setData(filteredData);
  };


  const filterCsrTasksData = (key, value, options) => {
    const filteredData = [];
    const object = options?.filter((each) => each?.value === key)[0] || {}

    if (!value || value?.trim() === "") {
      setTaskData(csrTaskData)
      return
    };

    const searchValue = value.replace(/\s+/g, "").toLowerCase();
    csrTaskData?.forEach((item => {
      let itemValue = getNestedValueFromObject(item, object?.objectKey, object?.type, object?.nested_key)?.replace(/\s+/g, "")?.toLowerCase();
      if (itemValue?.includes(searchValue)) {
        filteredData.push(item);
      }
    }))
    setTaskData(filteredData)
  }
  const handleSearchBarUICss=(value,userRole)=>{
    if(value===CSR_TASK){
     if( userRole==="ADVISOR" || (userRole === USER_ROLE.OPERATION_EXECUTIVE || userRole === USER_ROLE.ADMIN)){
        return true
      }
      return false
    }else{
      if( userRole==="ADVISOR" ){
        return true
      }
      return false
    }
  
  }
  const getcsrTaskOption = (options)=>{
    if (userRole !== "ADVISOR"){
      return [...options,csrNameOptionForCsrTask]
    }
    else {
      return options
    }
  }

  useEffect(() => {
    if (value === CSR_TASK) {
      getAllCsrTasks(taskState);
    }
  }, [value, taskState])

  return loader ? (
    <LoaderPage />
  ) : (
    <StyledTaskManager isActionItemPending={value === "ACTION_ITEM_PENDING"} isCsrTask= {handleSearchBarUICss(value,userRole)}>
      <TabContext value={value} variant="fullWidth">
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            width: "100%",
          }}
        >
          <TabList
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="lab API tabs example"
          >
            {filteredTabList.map((d, i) => {
              return <Tab key={i} sx={{ width: 200 }} {...d} />;
            })}
          </TabList>
        </Box>
        {value === "ACTION_ITEM_PENDING" && (
          <Flex className="filter-style">

            <>
              <ActionFilter
                actionSelected={actionSelected}
                handleSelect={(value) => {
                  setActionSelected(value);
                }}
              />
            </>

            <SearchBar options={options} onSearchClick={(key, value) => filterDataByKeyValue(key, value, options)} />
          </Flex>
        )}




        {value === Rent_Agreement && <RentAgreement />}
        {value !== Rent_Agreement && tabPanelList.map((tableData, i) => {
          return (
            <TabPanel
              key={i}
              value={tableData.value}
              sx={{ width: "100%", padding: 0 }}
            >
              <div className="filter-grid">


                {userRole === "ADVISOR" && (
                  <Toggle
                    selectedValue={selectedAdvisorType || "Primary"}
                    firstValue="Primary"
                    secondValue="Secondary"
                    handleSelectedValue={(value) => {
                      setSelectedAdvisorType(value.toUpperCase());
                    }}
                  />
                )}
                {(userRole === USER_ROLE.OPERATION_EXECUTIVE || userRole === USER_ROLE.ADMIN) &&
                  value === CSR_TASK && (

                    <div className="toggle">
                      <Radio.Group
                        onChange={(e) => handleTaskStateChange(e.target.value)}
                        value={taskState}
                        buttonStyle="solid"
                      >
                        <Radio.Button value={TASK_STATUS.ACTIVE}>All</Radio.Button>
                        <Radio.Button value={TASK_STATUS.PENDING}>Pending</Radio.Button>
                        <Radio.Button value={TASK_STATUS.IN_PROGRESS}>In Progress</Radio.Button>
                        <Radio.Button value={TASK_STATUS.COMPLETED}>Completed</Radio.Button>
                      </Radio.Group>
                    </div>


                  )}
                <div className="task-search-bar">
                  {
                    value === CSR_TASK &&

                    <SearchBar options={getcsrTaskOption(csrTaskTableOptions)} onSearchClick={(key, value) => filterCsrTasksData(key, value, csrTaskTableOptions)} />

                  }
                  {
                    value !== CSR_TASK && value !== "ACTION_ITEM_PENDING" &&  <SearchBar options={options} onSearchClick={(key, value) => filterDataByKeyValue(key, value, options)} />
                  }
                
                </div>
              </div>
              <div className="resultS">
                <CardAddSlot>
                  <BasicExampleDataGrid {...tableData} />
                </CardAddSlot>
              </div>
            </TabPanel>
          );
        })}
      </TabContext>
      <CsrTaskModal isVisible={isTaskModalVisible} onCancel={handleCsrTaskModalClose} task={selectedCsrTask} userRole={userRole} onSuccess={getAllCsrTasks} />
    </StyledTaskManager>
  );
};

export default TaskManager;