import React from "react";
import Button from "../../commonComponents/Button";
import "./signup.css";
import { signInWithGoogle, signup, updateUser } from "../../firebase";
import { useRef, useState } from "react";
import { useNavigate } from "react-router";
import Divider from "../../commonComponents/Divider";
import GoogleBtn from "../../commonComponents/GoogleBtn";
import { dim, theme } from "../../styles/theme";
import { getType } from "../../Services/AdvisorDoc";
import toast from "react-hot-toast";
import {LOCAL_DB_KEYS} from "../../constants/localStorage";

const SignupPage = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const emailRef = useRef();
  const passwordRef = useRef();
  const nameRef = useRef();
  const cPasswordRef = useRef();

  function handleSignup(e) {
    setLoading(true);
    e.preventDefault();
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    const name = nameRef.current.value;
    const cPassword = cPasswordRef.current.value;

    if (email.length <= 6 && password.length <= 5) {
    } else if (password === "") {
      setError("please set a password");
    } else if (cPassword === "") {
      setError("please set confirm password");
    } else if (password !== cPassword) {
      setError("password mismatch");
    } else {
      setError("");

      try {
        signup(email, password)
          .then(function (data) {
            handleValueToBeSavedAtLogin(data, name);
          })
          .catch(function (error) {
            var error_message = error.message;

            alert(error_message);
          });
      } catch (e) {
        alert("Error", e);
      }
    }
    setLoading(false);
  }

  const handleValueToBeSavedAtLogin = async (data, name) => {
    const toastId = toast.loading("Loading...");
    const user = data.user;
    const displayName = user?.displayName || name;

    const res = await updateUser(displayName, user.uid);
    const typeOfUser = await getType();

    if (typeOfUser?.user_type !== undefined) {
      localStorage.setItem(
        "user",
        JSON.stringify({
          displayName: displayName,
          id: user.uid,
        })
      );
      localStorage.setItem(
        LOCAL_DB_KEYS.CURRENT_USER,
        JSON.stringify(typeOfUser)
      );
      localStorage.setItem("type", JSON.stringify(typeOfUser));
      localStorage.setItem("loggedIn", true);
      localStorage.setItem("email", JSON.stringify(user?.email));

      props.setLoggedIn();
      toast.dismiss(toastId);
      navigate("/");
    } else {
      toast.dismiss(toastId);
      alert("Please use correct credential or you are not authorized");
    }
  };

  return (
    <div className="parentSignup">
      <form className="signupForm">
        <p>Signup to Prosperr CMS</p>
        <input
          type="text"
          id="first_name"
          name="firstname"
          placeholder="*Full Name"
          ref={nameRef}
        />

        <input
          type="email"
          id="email"
          name="email"
          placeholder="*Email"
          ref={emailRef}
          onChange={(e) => {
            props.setUser(e.target.value);
          }}
        />
        <input
          type="password"
          id="password"
          name="password"
          placeholder="*Password"
          ref={passwordRef}
        />
        <input
          type="password"
          id="password"
          name="cPassword"
          placeholder="*Confirm Password"
          ref={cPasswordRef}
        />
        <p className="error">{error}</p>
        <Button
          buttonName="Signup"
          buttonClassName="signUpButton"
          onButtonClick={(e) => handleSignup(e)}
          onDisabled={loading}
        />

        <Divider
          style={{
            background: theme.color.grey_100,
            margin: `${dim._16px} 0`,
            marginLeft: 0,
            marginRight: 0,
          }}
          width={"100%"}
          height={1}
        >
          Or
        </Divider>

        <GoogleBtn
          style={{ height: "48px", width: "100% ", borderRadius: "58px" }}
          onClick={(e) => {
            signInWithGoogle(handleValueToBeSavedAtLogin);
          }}
        />
      </form>
    </div>
  );
};

export default SignupPage;
