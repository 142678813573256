import { Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-hot-toast";
import CardAddSlot from "../../commonComponents/CardAddSlot/CardAddSlot";
import LoaderPage from "../../commonComponents/LoaderPage";
import BasicExampleDataGrid from "../../components/SuperSaverTable/SuperTable";
import { getAllRentAgreement } from "../../Services/RentAgriment";
import { StyledRentAgreement } from "./RentAgreement.styled";
import DataGridCustomTooltip from "../../commonComponents/DataGridCustomTooltip";
import { httpClient } from "../../Services/httpUtil";
import { LOCAL_DB_KEYS } from "../../constants/localStorage";
import { getDate } from "../../utils/getDate";
import { USER_ROLE } from "../../constants/userRole";
import { useSelector } from "react-redux";

const RentAgreement = () => {
  const [Data, setData] = useState([]);
  const [loader, setLoader] = useState(true);

    const remoteConfig = useSelector((state) => state?.RemoteConfigReducer);
    const {RentAgreementFeatureFlag}=remoteConfig||{};
    const isRentAgreementFeatureEnabled=JSON.parse(RentAgreementFeatureFlag);

  useEffect(async () => {
    const currentUser = JSON.parse(localStorage.getItem(LOCAL_DB_KEYS.CURRENT_USER)).user_roll
    let user_id = 0
    if(currentUser === USER_ROLE.OPERATION_EXECUTIVE)
      user_id = JSON.parse(localStorage.getItem(LOCAL_DB_KEYS.USER_DETAILS)).id
    
    try {
      const data = await getAllRentAgreement(user_id);
      setData(data);
      setLoader(false);
    } catch (e) {
      
      toast.error(e);
    }
  }, []);

  const AgrementColumns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "land_lord_pancard",
      headerName: "Land lord pancard",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: "land_lord_name",
      headerName: "Land lord name",
      flex: 1,
      renderCell: (params) => <DataGridCustomTooltip dataVal={params?.value} />,
    },
    {
      field: isRentAgreementFeatureEnabled?"requested_date":"duration",
      headerName: isRentAgreementFeatureEnabled?"Requested Date":"Duration",
      flex: 1,
      renderCell: (params) => (
        <DataGridCustomTooltip dataVal={params?.value} showCCB={false} />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <DataGridCustomTooltip dataVal={params?.value} showCCB={false} />
      ),
    },
  ];
  let userRole = JSON.parse(localStorage.getItem(LOCAL_DB_KEYS.USER)).user_roll;
   
  if(isRentAgreementFeatureEnabled && (userRole === USER_ROLE.ACCOUNTANT || userRole === USER_ROLE.ADMIN)) {
    AgrementColumns.splice(6, 0, {
      field: "assignee",
      headerName: "Assignee",
      flex: 1,
      renderCell: (params) => (
        <DataGridCustomTooltip dataVal={params?.value} showCCB={false} />
      ),
    },)
   }

  function rowFunction(value, index, array) {
    return {
      id: index,
      name: value.name,
      status: value.status,
      mobile: value.mobile,
      email: value.email,
      land_lord_name: value.land_lord_details.name,
      land_lord_pancard: value.land_lord_details.pancard,
      requested_date: getDate(value.created_at),
      duration: getDate(value.start_date, value.end_date),
      details: value.details,
      land_lord_details: value.land_lord_details,
      externalId: value.external_id,
      assignee: value.assignee || "Rishab",
      state: value.state
    };
  }
  const handleOnCellClick = (params, navigate) => {
    if (params.field === "actions") {
      return;
    }
    navigate("/rent-agreement/details", {
      state: { item: params.row },
    });
  };

  return (
    <>
      {loader ? (
        <LoaderPage />
      ) : (
        <StyledRentAgreement>
          <div className="resultS">
            <div className=""></div>
            <CardAddSlot>
              <BasicExampleDataGrid
                data={Data}
                columns={AgrementColumns}
                rowFunction={rowFunction}
                handleOnCellClick={handleOnCellClick}
              />
            </CardAddSlot>
          </div>
        </StyledRentAgreement>
      )}
    </>
  );
};

export default RentAgreement;
