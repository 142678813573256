import React from 'react'
import Select from "react-select";

const Dropdown = ({
    label,
    options,
    isSearchable = false,
    name,
    className = "dropdown-plans",
    value,
    onChange,
    id,
  }) => {
    return (
      <div className="flex paymentWrapper" id={id}>
        <label htmlFor="date">{label}:</label>
        <Select
          options={options}
          isSearchable={isSearchable}
          value={value}
          className={className}
          name={name}
          onChange={onChange}
          classNamePrefix="react-select"
        />
      </div>
    );
  };

export default Dropdown