import styled from "styled-components";

const SearchbarStyle = styled.div`
       display:flex;
       justify-content:flex-end;
       align-items:center;
       position:relative;
       padding:5px 0px;
       width:60%;

      .ant-input{
         padding: 6px;
         color: black;
         width:300px;
      }
      .clear-icon{
       position:absolute;
        right:10px;
        z-index:2;
        background-color:white;
        top: 50%;
        transform: translateY(-50%);
        cursor:pointer;
      }
      .search-icon{
        position:absolute;
        right:10px;
        z-index:2;
        background-color:white;
        top: 50%;
        transform: translateY(-50%);
        cursor:pointer;
       }
        .ant-select{
        height:36px;
        width:100px;
        }
      .ant-btn{
           background-color:#0e6ab0;
           color:white;
           padding:17px;
      }
           

   
`;

export default SearchbarStyle;


