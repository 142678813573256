import BASE_URL from "./API_ENDPOINT";
import { getAuthToken, httpClient } from "./httpUtil";

export const RentAgreementUrl = {
  initiateUpload: async ( document_type, owner_of_document, uploaded_by, financial_year_id, file_name, file_type) => {
    const url = `${BASE_URL}user-document/initiate-upload`;
    const response = await httpClient.post(url, {
      document_type, owner_of_document, uploaded_by, financial_year_id, file_name, file_type
    });
    const data = await response?.data;
    return data;
  }
};
