import axios from "axios";
import { toast } from "react-hot-toast";
import { RentAgreementUrl } from '../Services/rentAgreementUrlApi'
import { getContentTypeOnExt } from "./getContentTypeOnExtension";

const RentAgreementUpload = ( documentType, ownerOfDocument, uploadedBy, financialYearId, fileName, fileType, ReturnValueSetter, imageFile) => {
  const preSignedObj = RentAgreementUrl.initiateUpload(
    documentType,
    ownerOfDocument,
    uploadedBy,
    financialYearId, 
    fileName, 
    fileType
  ).then((preSignedObj) => {
    const headers = {
      'Content-Type': getContentTypeOnExt(fileType.toLowerCase())
  };
  const params = {};
  let options = {params, headers};
    return axios
      .put(preSignedObj?.pre_signed_s3_url_for_upload, imageFile,options)
      .then(() => {
        ReturnValueSetter(preSignedObj?.document_identifier);
      })
      .catch((err) => {
        toast.error(err);
      });
  });
  return preSignedObj;
};
export { RentAgreementUpload };
