import React from "react";
import {Wrapper} from "../../../../components/PaymentBreakdown/index.styled";
import {IoArrowBack} from "react-icons/io5";
import {getGstAmount, roundNumber} from "../../../../utils/getGstAmount";
const PaymentBreakdownConfirmation = ({handleStep, formValues}) => {
  const {
    planType,
    planAmount,
    dueAmount,
    discount_amount,
    partialPayment,
    amount,
    consumed_coins,
    current_plan_discounted_price
  } = formValues;

  const paymentInfo=[
    {
      label:'SUPER SAVER',
      value:planType
    },
    {
      label:'h1'
    },

    {
      label:'Plan Price',
      value:planAmount
    },
    {
      label:'Due amount',
      value:dueAmount
    },
    {
      label:'Discount',
      value:discount_amount,
      type:'green'

    },
    {
      label:'Coin Used',
      value:consumed_coins,
      type:'green'
    },
    {
      label:'Previous plan price',
      value:current_plan_discounted_price,
      type:'green'
    },
    {
      label:'h1'
    },
    {
      label:'Purchase amount',
      value:roundNumber(amount-getGstAmount(+amount))
    },
    {
      label:'GST @18%',
      value:roundNumber(getGstAmount(+amount))
    },
    {
      label:'h1'
    },
    {
      label:partialPayment
      ? "Total purchase amount (with GST)"
      : "To Pay (with GST)",
      value:amount
    },
 
  ]
  return (
    <Wrapper>
      <h3 className="heading">
        {" "}
        <IoArrowBack
          onClick={() => {
            handleStep(1);
          }}
        />{" "}
        Payment Details
      </h3>
      <hr className="hr"></hr>
      <div className="flex-row">
        <h3 className="text-b">SUPER SAVER</h3>
        <h3 className="text-b">{planType}</h3>
      </div>

      {
        paymentInfo.map((d)=>{
          if(d.label=='h1'){
            return <hr className="hr" style={{marginTop: 16}}></hr>
          }
          if(d.value>0){
            return    <div className="flex-row"
            style={d.type==='green'?{color: "green"}:{}}
            >
            <h3 className="text-b">
              {d.label}
            </h3>
            <h3 className="text-b">{d.type==='green'?'-':''} ₹{d.value}</h3>
          </div>
          }
         
        })
      }

      {partialPayment && <hr className="hr" style={{marginTop: 16}}></hr>}
      {partialPayment && (
        <div className="flex-row">
          <h3 className="text-b">Partial payable amount</h3>
          <h3 className="text-b">₹{partialPayment}</h3>
        </div>
      )}

      <div className="bottom-sheet">
        <img
          className="images-safe"
          src={process.env.PUBLIC_URL + "/assets/images/security/safe.png"}
          alt=""
        ></img>
      </div>
    </Wrapper>
  );
};

export default PaymentBreakdownConfirmation;
